import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Courses = () => {
  return (
    <>
      <Header />

      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Learn like top IITians and achieve high-paid
                            software jobs
                          </h2>
                          <p style={{ color: "black" }}>
                            Work on real-world projects and gain
                            industry-required skills Our top notch teams
                            involved in helping you learn programming, not just
                            coding. fostering accountability within our
                            sustainability framework: People, Planet, Profit.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            {/* <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View Courses</a>
                              </div>
                            </Link> */}
                            <Link to="/job-seekers" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/all-courses.png"></img>
                      {/* <form
                        // method="post"
                        // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                        id="contact-form"
                        class="default-form form-group"
                      >
                        <div class=" clearfix">
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="username"
                              placeholder="Your Name *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="email"
                              // name="email"
                              placeholder="Email Address *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="phone"
                              required=""
                              placeholder="Phone *"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="subject"
                              required=""
                              placeholder="Subject"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                            <button
                              class="theme-btn-one"
                              type="submit"
                              // name="submit-form"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}

            {/* <ul class="bread-crumb clearfix">
              <li>
                <a href="">Home</a>
              </li>
              <li>About</li>
              <li>About Ideants</li>
            </ul> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      <section className="" style={{ marginTop: "-80px" }}>
        <div className="">
          <div className="sec-title centred mt-5 pt-5 ">
            <span className="top-title">Our Training Programs</span>
            <h2>
              Ignite Your Tech Career Today: <br></br>
              Learn, Grow, and Get Placed{" "}
            </h2>
          </div>
        </div>
      </section>

      <div className="innerContent-wrap mb-5">
        <div className="container">
          <div className="class-wrap">
            <ul className="row unorderList">
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/ui-ux.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">UI / UX Design Specialization</a>
                    </h4>
                    <p>
                      Design High-Impact User Experiences. Research, design, and
                      prototype effective, visually-driven websites and apps.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/UI-UX-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/UI-UX-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/react-front-end.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">React-Front End Web Developer</a>
                    </h4>
                    <p>
                      Front-end Web Developer Fellowship is to help you master
                      the fundamentals of web development and break into a
                      career.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/react-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/react-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/react-native.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">React Native developer</a>
                    </h4>
                    <p>
                      Build cross-platform mobile apps with JavaScript,
                      leveraging React's power for seamless native experiences.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/ReactNative-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/ReactNative-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/angular.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Angular-Front End Web Developer</a>
                    </h4>
                    <p>
                      A powerful TypeScript-based framework for building dynamic
                      web applications with modular and scalable architecture.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/Angular-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/Angular-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/dotnet.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">.NET FullStack Developer</a>
                    </h4>
                    <p>
                      Build Fullstack webapp with .NET technologies. Master the
                      intricacies of .NET core and ASP.NET backend development.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/mern.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">MERN FullStack Developer</a>
                    </h4>
                    <p>
                      You will learn to build dynamic, scalable web applications
                      using MongoDB, Express.js, React, and Node.js
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/Java-Full-Stack.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Full Stack Java Developer</a>
                    </h4>
                    <p>
                      Streamline your Java application development with
                      simplicity, speed, and robustness for modern, scalable
                      projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/devops.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">DevOps Advanced Certification</a>
                    </h4>
                    <p>
                      The program is designed by industry experts to help you
                      master AWS, cloud computing, VPC, etc., through real-time
                      hands-on projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>



      <div className="innerContent-wrap mb-5">
        <div className="container">
          <div className="class-wrap">
            <ul className="row unorderList">
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/ui-ux.png"
                      src="assets/images/courses/vlsi-physical-design.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI PHYSICAL DESIGN</a>
                    </h4>
                    <p>
                    Be that Physical Designer with the world-class industry-oriented VLSI – Physical Design training program using the Cadence Encounter tool.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-PHYSICAL-DESIGN-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-PHYSICAL-DESIGN-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/react-front-end.jpg"
                      src="assets\images\courses\vlsi-analog-layout.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI ANALOG LAYOUT</a>
                    </h4>
                    <p>
                    Be that Analog Layout Designer with the world-class industry-oriented training program using the Cadence Virtuoso tool.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-ANALOG-LAYOUT-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-ANALOG-LAYOUT-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/react-native.jpg"
                      src="assets\images\courses\VLSI-Design-Verification.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI Design Verification</a>
                    </h4>
                    <p>
                    Bridging innovation with precision through industry-grade VLSI Design Verification training using Cadence & Mentor Graphics tools.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/VLSI-design-verification-details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/VLSI-design-verification-details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/angular.jpg"
                      src="assets\images\courses\VLSI-Embedded-Systems.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI Embedded Systems</a>
                    </h4>
                    <p>
                    Bring the change by joining the world-class industry-oriented Embedded Systems training program offered by ROUTE2SOC.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-Embedded-Systems-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-Embedded-Systems-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>

              {/* <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/dotnet.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">.NET FullStack Developer</a>
                    </h4>
                    <p>
                      Build Fullstack webapp with .NET technologies. Master the
                      intricacies of .NET core and ASP.NET backend development.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li> */}

              {/* <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/mern.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">MERN FullStack Developer</a>
                    </h4>
                    <p>
                      You will learn to build dynamic, scalable web applications
                      using MongoDB, Express.js, React, and Node.js
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li> */}
              {/* <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/Java-Full-Stack.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">Full Stack Java Developer</a>
                    </h4>
                    <p>
                      Streamline your Java application development with
                      simplicity, speed, and robustness for modern, scalable
                      projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li> */}
              {/* <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/devops.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">DevOps Advanced Certification</a>
                    </h4>
                    <p>
                      The program is designed by industry experts to help you
                      master AWS, cloud computing, VPC, etc., through real-time
                      hands-on projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Courses;
