import Footer from "../../../Blog/Footer/Footer";
import Header from "../../../Blog/Header/Header";
// import Sidebar from "../sidebar/Sidebar";
import Sidebar from "../Sidebar";
import './Home.css';
import { Outlet, useOutlet } from "react-router-dom";

const Home = () => {
  const outlet = useOutlet();

  return (
    <>
    <Header/>
    <div className="home"> 
    
      <Sidebar />
      <div className="homeContainer">
        {outlet === null && (
          <div className="oulet">
            <div className="customImageContainer">
              {/* <h1>Dashboard content </h1> */}
              <img src="assets/images/about/about-us.jpg" alt="" />
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Home;
