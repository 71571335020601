import React, { useState } from 'react';
import { Link } from "react-router-dom";

import Header from '../../../Header/Header';
import Footer from '../../../Footer/Footer';

import Clients from "../../../Clients/Clients";

const PlacementGuarantee = () => {
    const [activeTab, setActiveTab] = useState("#tab-3");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (

    <div>
      <Header/>
        {/* <!--Page Title--> */}
        <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Full Stack Development & 100% placement guarantee
                          </h2>
                          <p style={{ color: "white" }}>
                          Enroll now for a program guaranteeing 100% placement, offering direct access to 5000+ partner companies. With 200 hours of live learning, 1:1 doubt resolution, and a flexible curriculum, seize the opportunity for lifelong skill enhancement and career advancement.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="/our-courses" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View Courses</a>
                              </div>
                            </Link>
                            <Link to="/job-seekers" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 content-column ">
                    <div>
                      <div class="ml-5">
                        <div class="job-sidebar">
                          <div class=" form-column" style={{}}>
                            <div
                              class="form-inner"
                              style={{
                                boxShadow: "0px 0px 1px 1px lightblue",
                                borderRadius: "15px",
                                borderTop: "15px solid #662d91",
                                paddingBottom: "13px",
                                backgroundColor: "white",
                                paddingLeft: "25px",
                                paddingRight: "25px",
                              }}
                            >
                              <form
                                // method="post"
                                // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                                id="contact-form"
                                class="default-form"
                              >
                                <div class=" clearfix">
                                  <h4 style={{ textAlign: "center" }}>
                                    Book a free Demo
                                  </h4>
                                  <p style={{ textAlign: "center" }}>
                                    {/* We are happy to help you 24/7 */}
                                  </p>

                                  <div class=" form-group mt-3">
                                    <input
                                      type="text"
                                      // name="username"
                                      placeholder="Your Name *"
                                      required=""
                                    />
                                  </div>

                                  <div class=" form-group">
                                    <input
                                      type="text"
                                      // name="phone"
                                      required=""
                                      placeholder="Phone *"
                                    />
                                  </div>
                                  <div class=" form-group">
                                    <input
                                      type="text"
                                      // name="phone"
                                      required=""
                                      placeholder="Email Id *"
                                    />
                                  </div>

                                  <div class=" form-group message-btn centred">
                                    <button
                                      class="theme-btn-one"
                                      type="submit"
                                      // name="submit-form"
                                    >
                                      Enroll Now
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      <section class="process-section" style={{ marginTop: "-5px", backgroundColor:"#f0e6e0" }}>
        <div class="container-fluid">
          <div class="row clearfix">
            
            <div class="col-lg-12 col-md-6 col-sm-12 process-block">
              <h1 style={{ textAlign: "center" ,fontSize:"55px",textDecoration:"underline"}}>
              Placement Guarantee Program<br></br>
Full Stack Development

              </h1><br></br>
              <p style={{ textAlign: "center", fontSize: "24px" }}>
                Our highly motivated team of experts are ready to help you to
                get your Dream Job.
              </p>
              <section class="about-section sec-pad" style={{marginTop:"-50px"}}>
        
                    <div class="auto-container">
                      <div class="inner-container" style={{padding:"20px",backgroundColor:"#f0e6e0"}}>
                        <div class="row clearfix">
                          <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                                          <figure>
                                            <img src='assets/images/mern.png'></img>
                                          </figure>
                          </div>
                          <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                          <figure>
                                            <img src='assets/images/react-front-end.png'></img>
                                          </figure>
                          </div>
                          <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                          <figure>
                                            <img src='assets/images/Java-Full-Stack.png'></img>
                                          </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                </section>
                <h1 style={{ textAlign: "center",marginTop:"-100px"}}>
                Learn from our top instructors<br></br>
Get a Guaranteed Job

              </h1>
              <h1 style={{ textAlign: "center",fontSize:"60px",color:"purple"}}>upto 25 LPA</h1>
              <section class="awards-section">
        <div class="auto-container">
          <div class="sec-title centred">
            <h2>500+ student’s life impacted and continuing</h2>
            <p>
              Learn from our top instructors Get a Guaranteed Job upto 25 LPA
            </p>
          </div>
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-1.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Naveen Kumar
                  </h3>
                  <p class="card-text">MERN Full Stack Developer</p>
                  {/* <h5 class="card-title">TCS</h5> */}
                  <p>Package: 6.8 LPA</p>

                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img src="assets/images/clients/tcs.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-2.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Vinay{" "}
                  </h3>
                  <p class="card-text">Java Developer</p>
                  {/* <h5 class="card-title">Microsoft</h5> */}
                  <p>Package: 10.5 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/microsoft.png"
                    alt=""
                    style={{ marginTop: "40px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-3.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Ramya
                  </h3>
                  <p class="card-text">Softeware Engineer</p>
                  {/* <h5 class="card-title">Infosys</h5> */}
                  <p>Package: 10 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/infosys.png"
                    alt=""
                    style={{ width: "120px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-4.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h4 class="card-title" style={{ fontWeight: "bold" }}>
                    Rakesh
                  </h4>
                  <p class="card-text">Web Developer</p>
                  {/* <h5 class="card-title">Google</h5> */}
                  <p>Package: 8.3 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/google.png"
                    alt=""
                    style={{ width: "150px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
              
            </div>
            
            
          </div>
        </div>
      </section><br></br>
      <Clients />
      <section class="about-section sec-pad" style={{marginTop:"0px"}}>
        <div
          class="pattern-layer"
          style={{ backgroundImage: "url('assets/images/shape/pattern-5.png)" }}
        ></div>
        <div class="auto-container">
          <div class="inner-container">
            <div class="row clearfix">
              <div class="col-lg-5 col-md-12 col-sm-12 content-column">
                <figure><img src="assets/images/long-term-1.png"></img></figure>
              </div>
              <div class="col-lg-7 col-md-12 col-sm-12 content-column" style={{padding:"20px"}}><br></br>
                <h2>Full Stack Development & 100% placement guarantee - Get confirmed salary up to ₹5.LPA </h2><br></br>
                <p style={{fontSize:"18px"}}>A Guide To Internships For Students: Landing, Navigating, And Making The Most Of Them</p><br></br>
                <ul style={{fontSize:"24px"}}>
                  <li><i class="flaticon-right-arrow"></i> 6 months | 200 hrs of learning
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> 9 Micro projects + Capstone projects
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> Lifetime access to Projects & class recordings
                  </li><br></br>
                  <li><i class="flaticon-right-arrow"></i> Daily ⬤ LIVE classes with the mentor
                  </li>  <br></br>
                  <li><i class="flaticon-right-arrow"></i> Completion Certificates
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      
      <section
        class="chooseus-section sec-pad-2"
        style={{ marginTop: "-150px" }}
      >
        <h2 style={{ textAlign: "center" }}>
          Ignite Your Tech Career Today: Learn, Grow, and Get Placed
        </h2>
        <br></br>
        <br></br>
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>1 Year of Rigorous training</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>Deployable from Day 1</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>Client Based Training</h3>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>8 Hours TrainingPer Day</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>Direct Client Payroll</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
              <div
                class="chooseus-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div class="inner-box">
                  <h3>MIN: 5,00,000 CTC PACKAGE</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pricing-section" style={{marginTop:"-100px"}}>
            <div class="auto-container">
                <div class="sec-title">
                    <h2>Impressive Course Fee</h2>
                </div>
                <div class="tabs-box">
                    
                    <div class="tabs-content">
                        <div class="tab active-tab" id="tab-15">
                            <div class="row clearfix">
                                <div class="col-lg-4 col-md-6 col-sm-12 pricing-block">
                                    <div class="pricing-block-two">
                                        <div class="inner-box">
                                            <div class="pricing-header centred">
                                                <figure class="icon-box"><img src="assets/images/icons/icon-35.png" alt=""></img></figure>
                                                <h3>BASIC</h3>
                                                <span class="text">PLACEMENT PREPARATION</span>
                                            </div>
                                            <h4>45 Days MERN stack + Resume building + Completion Certificates</h4><br></br>
                                            <h2><span class="symble">₹</span>24,999 </h2>
                                            <a href="index-2.html">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 pricing-block">
                                    <div class="pricing-block-two">
                                        <div class="inner-box">
                                            <div class="pricing-header centred">
                                                <figure class="icon-box"><img src="assets/images/icons/icon-36.png" alt=""></img></figure>
                                                <h3>PRO</h3>
                                                <span class="text">GUARANTEED INTERNSHIP</span>
                                            </div>
                                            <h4>100% Refundable if you don’t get Internship</h4><br></br>
                                            <h4>Stipend upto 6k month</h4><br></br>
                                            <h4>6 months MERN stack + Interview prep + Resume building</h4><br></br>
                                            <h2><span class="symble">₹</span>59,999 </h2>
                                            <a href="index-2.html">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 pricing-block">
                                    <div class="pricing-block-two">
                                        <div class="inner-box">
                                            <div class="pricing-header centred">
                                                <figure class="icon-box"><img src="assets/images/icons/icon-37.png" alt=""></img></figure>
                                                <h3>PRO PLUS</h3>
                                                <span class="text">GUARANTEED JOB</span>
                                            </div>
                                            <h4>100% Refundable if you don’t get Job</h4><br></br>
                                            <h4>CTC upto 25 LPA</h4><br></br>
                                            <h4>6 months MERN stack + DSA + Interview Prep + Resume building</h4><br></br>
                                            <h2><span class="symble">₹</span>119,999 </h2>
                                            <a href="index-2.html">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
      <Footer/>
    </div>
  );
}

export default PlacementGuarantee;
