import React from "react";
import Header from "./Header/Header";
import Slider from "./Slider/Slider";
import Clients from "./Clients/Clients";
import Welcome from "./Welcome/Welcome";
import About from "./About/About";
import Service from "./Service/Service";
import Recruitment from "./Recruitment/Recruitment";
import Industries from "./Industries/Industries";
import Process from "./Process/Process";
import Team from "./Team/Team";
import Testimonial from "./Testimonial/Testimonial";
import Footer from "./Footer/Footer";
import Training from "./Training/Training";
import VLSICerificationTraining from "./VLSI certification program/VLSICerificationTraining";

function Blog() {
  return (
    <>
      <Header />
      <Slider />
      <Welcome />
      <Service />
      {/* <About /> */}
      <Training />
      <VLSICerificationTraining/>
      <Clients />
      <Recruitment />
      <Industries />
      <Process />
      {/* <Team /> */}
      <Testimonial />
      <Footer />
    </>
  );
}

export default Blog;
