import React from "react";

const Process = () => {
  return (
    <div>
      <section className="process-section centred">
        <div className="auto-container">
          <div className="sec-title">
            <span className="top-title">How it’s Possible</span>
            <h2>Our Sustainable Work Manifesto</h2>
            <p>
              Long established fact that a reader will be distracted by the
              <br />
              readable content of a page.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 process-block">
              <div
                className="process-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <figure className="icon-box">
                    <img src="assets/images/icons/icon-18.png" alt="" />
                    <span>01</span>
                    <div className="anim-icon">
                      <div
                        className="icon-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-8.png)",
                        }}
                      ></div>
                      <div
                        className="icon-2 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                      <div
                        className="icon-3 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                    </div>
                  </figure>
                  <div className="lower-content">
                    <h3>People</h3>
                    <p>
                      Grasp the meaning and value of work, take care of your
                      psycho-physical well-being. Invest in developing hard and
                      soft skills and build professional and leadership
                      relationships
                    </p>
                    <a href="#">More Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 process-block">
              <div
                className="process-block-one wow fadeInUp animated animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <figure className="icon-box">
                    <img src="assets/images/icons/icon-56.png" alt="" />
                    <span>02</span>
                    <div className="anim-icon">
                      <div
                        className="icon-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-8.png)",
                        }}
                      ></div>
                      <div
                        className="icon-2 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                      <div
                        className="icon-3 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                    </div>
                  </figure>
                  <div className="lower-content">
                    <h3>Companies</h3>
                    <p>
                      Improve resiliency, give priority to innovation and value
                      creation, invest in human capital. Promote diversity and
                      inclusion, reduce waste and pay attention to environmental
                      issues.
                    </p>
                    <a href="#">More Details</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 process-block">
              <div
                className="process-block-one wow fadeInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <figure className="icon-box">
                    <img src="assets/images/icons/icon-68.png" alt="" />
                    <span>03</span>
                    <div className="anim-icon">
                      <div
                        className="icon-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-8.png)",
                        }}
                      ></div>
                      <div
                        className="icon-2 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                      <div
                        className="icon-3 rotate-me"
                        style={{
                          backgroundImage:
                            "url(assets/images/icons/anim-icon-2.png)",
                        }}
                      ></div>
                    </div>
                  </figure>
                  <div className="lower-content">
                    <h3>Institutions & Society</h3>
                    <p>
                      Develop and support active labour policies, promote
                      sustainable flexibility, reduce the mismatching of skills.
                      Fight undeclared work and tax evasion and promote training
                      to enhance people’s employability.
                    </p>
                    <a href="#">More Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Process;
