import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

import "./About.css";

const About = () => {
  return (
    <>
      <Header />

      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)"
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box" >
           
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>We are Ideants, your trusted growth partner</h2>
                          <p style={{ color: "black" }}>
                          We connect talent with fitting roles and assist businesses in achieving their goals.
                           Our approach involves employing management tools and principles, alongside policies 
                           fostering accountability within our sustainability framework: People, Planet, Profit.
                          </p>{" "}
                          <br />
                        <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="/job-seekers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/about/about-us.jpg"></img>
                      {/* <form
                        // method="post"
                        // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                        id="contact-form"
                        class="default-form form-group"
                      >
                        <div class=" clearfix">
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="username"
                              placeholder="Your Name *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="email"
                              // name="email"
                              placeholder="Email Address *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="phone"
                              required=""
                              placeholder="Phone *"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="subject"
                              required=""
                              placeholder="Subject"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                            <button
                              class="theme-btn-one"
                              type="submit"
                              // name="submit-form"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}

            {/* <ul class="bread-crumb clearfix">
              <li>
                <a href="">Home</a>
              </li>
              <li>About</li>
              <li>About Ideants</li>
            </ul> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      <div className="aboutus">
        <div
          className="pattern-layer"
          style={{
            backgroundImagr: "url(assets/images/shape/pattern-5.png)",
          }}
        ></div>
        <div className="auto-container">
          <div className="inner-container">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_2">
                  <div className="content-box centred">
                    <figure className="image-box">
                      <img src="assets/images/resource/about-1.jpg" alt="" />
                    </figure>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_3">
                  <div className="content-box">
                    <div className="sec-title">
                      <span className="top-title">
                        About Ideants Technologies
                      </span>
                      <h2>
                        Staffing Partners: Reliable & Cost Efficiant Recruitment
                        Agency
                      </h2>
                    </div>
                    <div className="text">
                      <p>
                        Ideant Technologies revolutionizes staffing with
                        advanced technology, matching businesses with top talent
                        effortlessly. Our streamlined platform ensures efficient
                        recruitment processes, delivering exceptional results
                        for both employers and candidates. Experience the future
                        of staffing solutions with Ideant Technologies.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- process-style-two --> */}
      <section class="process-style-two alternet-2">
        <div class="auto-container">
          <div class="inner-container">
            <div
              class="pattern-layer"
              style={{
                backgroundImage: "url(assets/images/shape/pattern-36.png)",
              }}
            ></div>
            <div class="sec-title light centred">
              <span class="top-title">How We Work</span>
              <h2>Our Plan & Working Style</h2>
            </div>
            <div class="row clearfix">
              <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                <div class="single-item">
                  <div class="inner-box">
                    <span>Step</span>
                    <h2>01...</h2>
                    <figure class="icon-box">
                      <img src="assets/images/icons/icon-51.png" alt="" />
                    </figure>
                    <h3>
                      <a href="">Application Screening & Scheduling</a>
                    </h3>
                    <p>
                      Efficiently manage application screening and scheduling
                      with our streamlined processes, saving you time and
                      resources.
                    </p>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>More Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                <div class="single-item">
                  <div class="inner-box">
                    <span>Step</span>
                    <h2>02...</h2>
                    <figure class="icon-box">
                      <img src="assets/images/icons/icon-52.png" alt="" />
                    </figure>
                    <h3>
                      <a href="">Employee On-Boarding Checklist</a>
                    </h3>
                    <p>
                      Ensure seamless onboarding with our comprehensive
                      checklist, guiding you through each step to welcome new
                      employees into your team.
                    </p>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>More Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                <div class="single-item">
                  <div class="inner-box">
                    <span>Step</span>
                    <h2>03...</h2>
                    <figure class="icon-box">
                      <img src="assets/images/icons/icon-53.png" alt="" />
                    </figure>
                    <h3>
                      <a href="">Take Care of Employee’s Benefits</a>
                    </h3>
                    <p>
                      Let us handle your employees' benefits, ensuring they
                      receive comprehensive care and support for their
                      well-being.
                    </p>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>More Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- process-style-two end --> */}

      {/* <!-- history-section --> */}
      <section class="history-section">
        <figure class="image-layer" style={{ marginTop: "300px" }}>
          <img src="assets/images/resource/history-1.png" alt="" />
        </figure>
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="sec-title">
                <span class="top-title">Our History</span>
                <h2>Taking a Look Back of Our History</h2>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="inner-box">
                <figure class="image-box">
                  <img src="assets/images/resource/history-1.jpg" alt="" />
                  <div class="dots-box"></div>
                </figure>
                <div class="content-box">
                  <div class="dots-box active"></div>
                  <div class="year-box">
                    <h3>2021</h3>
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-37.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-38.png)",
                      }}
                    ></div>
                  </div>
                  <div class="text">
                    <h3>Fast Growing Company</h3>
                    <p>
                      Join our fast-growing company and be part of a dynamic
                      team driving innovation and success in the industry.
                    </p>
                  </div>
                </div>
                <figure class="image-box ">
                  <img src="assets/images/resource/history-2.jpg" alt="" />
                  <div class="dots-box"></div>
                </figure>
                <div class="content-box mr-0">
                  <div class="dots-box"></div>
                  <div class="year-box">
                    <h3>2023</h3>
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-37.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-38.png)",
                      }}
                    ></div>
                  </div>
                  <div class="text">
                    <h3>100 Companies Tie-up</h3>
                    <p>
                      We've established partnerships with 100 companies,
                      offering diverse opportunities and expanding horizons for
                      our candidates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 column">
              <div class="inner-box">
                <div class="content-box">
                  <div class="year-box">
                    <h3>2019</h3>
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-37.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-38.png)",
                      }}
                    ></div>
                  </div>
                  <div class="text">
                    <h3>Started in Bengaluru</h3>
                    <p>
                      Established in Bengaluru, where innovation meets
                      opportunity, we're proud to serve clients with excellence
                      and dedication
                    </p>
                  </div>
                </div>
                <figure class="image-box">
                  <img src="assets/images/resource/history-3.jpg" alt="" />
                </figure>
                <div class="content-box">
                  <div class="year-box">
                    <h3>2022</h3>
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-37.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-38.png)",
                      }}
                    ></div>
                  </div>
                  <div class="text">
                    <h3>Best Staffing Talent Award </h3>
                    <p>
                      Proud recipients of the Best Staffing Talent Award,
                      showcasing our commitment to excellence and unmatched
                      service in the industry.
                    </p>
                  </div>
                </div>
                <figure class="image-box mr-0">
                  <img src="assets/images/resource/history-4.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- history-section end --> */}
      <Footer />
    </>
  );
};

export default About;
