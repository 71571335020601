import React from "react";

const Welcome = () => {
  return (
    <>
      <div>
      <section class="about-style-two">
      <h2 style={{textAlign:"center",marginTop:"-50px"}}>Give the Best Start to Your Career</h2>
      <h4 style={{textAlign:"center"}}>Learn, practice, and get hired!</h4><br></br>
            <div class="auto-container">
                <div class="inner-container">
                    <div class="row clearfix">
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="card mb-3" style={{maxWwidth: "540px;",padding:"0px",backgroundColor:"#f2e9e3"}}>
                            <div class="row g-0">
                              <div class="col-md-4" style={{backgroundColor:"#17a2b8b8 ",clipPath:"polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",margin:"0px",padding:"0px"}}>
                                <img src="assets/images/certificate.png" class="img-fluid rounded-start" alt="..."></img>
                              </div>
                              <div class="col-md-8">
                                <div class="card-body">
                                  <h4 class="card-title">Certification courses</h4>
                                  <p class="card-text">Real success requires the right skillset. Through our online courses, you too can give wings to your dreams.</p>
                                  <div className="link">
                                    <a href="/our-courses"style={{fontSize:"18px"}}>Explore Now</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="card mb-3" style={{maxWwidth: "540px;",padding:"0px",backgroundColor:"#f2e9e3"}}>
                            <div class="row g-0">
                              <div class="col-md-4" style={{backgroundColor:"#662d9199 ",clipPath:"polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",margin:"0px",padding:"0px"}}>
                                <img src="assets/images/certificate.-2.png" class="img-fluid rounded-start" alt="..."></img>
                              </div>
                              <div class="col-md-8">
                                <div class="card-body">
                                  <h4 class="card-title">Short & Long Term Internship, Placement Guarantee Program</h4>
                                  <p class="card-text">Upskill and get a guaranteed placement.</p>
                                  <div className="link">
                                    <a href="/professional-certification-training" style={{fontSize:"18px"}}>Explore Now</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="welcome-section" style={{marginTop:"-150px"}}>
          <div className="auto-container">
            <div className="sec-title centred">
              <span className="top-title">Welcome to Ideants Technologies</span>
              <h2>Staffing Partners: Reliable & Cost Efficiant<br></br> Recruitment Agency</h2>
              <p>
                Welcome to Ideants Technologies, where cutting-edge solutions
                meet the modern workforce,
                <br />
                connecting talent with opportunity seamlessly.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <figure className="image-box js-tilt">
                  <img src="assets/images/resource/welcome-1.png" alt="" />
                </figure>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_1">
                  <div className="content-box">
                    <div className="tabs-box">
                      <div className="tabs-content">
                        <div className="tab active-tab" id="tab-1">
                          <div className="inner-box">
                            
                            <h2>
                              Hire Your Next Candidate With Ideants Technologies
                            </h2>
                            <p>
                              Discover top talent effortlessly with Ideants
                              Technologies: Your premier destination for hiring
                              your next standout candidate. Experience unmatched
                              efficiency and precision in your hiring process
                              with Ideants Technologies at your side.
                            </p>
                            <ul className="list clearfix">
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-1.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Understand Your Needs</h4>
                              </li>
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-2.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Find the Perfect Candidate</h4>
                              </li>
                            </ul>
                            <div className="link">
                              <a href="">
                                <i className="flaticon-right-arrow"></i>Your
                                Required Talent
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="tab" id="tab-2">
                          <div className="inner-box">
                            <h5>Find Your Right Place</h5>
                            <h2>
                              Explore Your Career Path With Ideants Technologies
                            </h2>
                            <p>
                              Embark on a journey of professional growth with
                              Ideants Technologies, where your career
                              aspirations find their perfect match. Discover
                              endless opportunities and unlock your potential
                              with our tailored staffing solutions.
                            </p>
                            <ul className="list clearfix">
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-71.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Executive Opportunities</h4>
                              </li>
                              <li>
                                <figure className="icon-box">
                                  <img
                                    src="assets/images/icons/icon-72.png"
                                    alt=""
                                  />
                                </figure>
                                <h4>Non Excecutive Opportunities</h4>
                              </li>
                            </ul>
                            <div className="link">
                              <a href="">
                                <i className="flaticon-right-arrow"></i>Your
                                Required Talent
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      {/* <!-- hiring-section --> */}
      <section class="hiring-section">
        <div class="image-layer">
          <figure class="image-1">
            <img src="assets/images/resource/hiring-1.png" alt="" />
          </figure>
          <figure class="image-2">
            <img src="assets/images/resource/hiring-2.png" alt="" />
          </figure>
        </div>
        <div class="outer-container clearfix">
          <div class="left-column pull-left clearfix">
            <div class="inner-box pull-right">
              <div class="icon-box">
                <i class="flaticon-factory"></i>
              </div>
              <h2 style={{textAlign:"right"}}>Industries <br></br>Hiring</h2>
              <p style={{textAlign:"right"}}> 
              Connecting skilled professionals with career opportunities across diverse industries, from technology and healthcare to finance and manufacturing.
              </p>
              <a href="our-services" style={{float:"right"}}>Industries</a>
            </div>
          </div>
          <div class="right-column pull-right clearfix">
            <div class="inner-box pull-left">
              <div class="icon-box">
                <i class="flaticon-working-man"></i>
              </div>
              <h2>Professions <br></br>Hiring</h2>
              <p>
              Matching skilled professionals with rewarding career opportunities across various fields, from IT and engineering to healthcare and finance.
              </p>
              <a href="our-services">Professions</a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- hiring-section end --> */}

      </div>
    </>
  );
};

export default Welcome;
