import React from "react";
import Footer from "../../../Blog/Footer/Footer";
import Header from "../../../Blog/Header/Header";
import Sidebar from "../../components/Sidebar";

const Addemployer = () => {
  return (
    <>   
    <Header/>
    <div className="d-flex">
      <Sidebar/> 
    <div>
      <h1>Add Employer</h1>
      <div className="container-fluid justify-content-cente">
        <div className="card p-4 rounded ">
          <form action="/action_page.php">
            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                id="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Password:</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                id="pwd"
              />
            </div>
            <div className="form-group form-check">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" /> Remember me
              </label>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
    <Footer/>
    </>

  );
};

export default Addemployer;
