import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Training.css";

const Training = () => {
  return (
    <>
      <section className="" style={{ marginTop: "-80px" }}>
        <div className="">
          <div className="sec-title centred mt-5 pt-5 ">
            <span className="top-title">Our Training Programs</span>
            <h2>
              Ignite Your Tech Career Today: <br></br>
              Learn, Grow, and Get Placed{" "}
            </h2>
          </div>
        </div>
      </section>

      <div className="innerContent-wrap mb-5">
        <div className="container">
          <div className="class-wrap">
            <ul className="row unorderList">
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/ui-ux.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">UI / UX Design Specialization</a>
                    </h4>
                    <p>
                      Design High-Impact User Experiences. Research, design, and
                      prototype effective, visually-driven websites and apps.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/UI-UX-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/UI-UX-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/react-front-end.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">React-Front End Web Developer</a>
                    </h4>
                    <p>
                      Front-end Web Developer Fellowship is to help you master
                      the fundamentals of web development and break into a
                      career.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/react-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/react-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/react-native.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">React Native developer</a>
                    </h4>
                    <p>
                      Build cross-platform mobile apps with JavaScript,
                      leveraging React's power for seamless native experiences.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/ReactNative-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/ReactNative-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/angular.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">Angular-Front End Web Developer</a>
                    </h4>
                    <p>
                      A powerful TypeScript-based framework for building dynamic
                      web applications with modular and scalable architecture.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/Angular-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/Angular-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/dotnet.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">.NET FullStack Developer</a>
                    </h4>
                    <p>
                      Build Fullstack webapp with .NET technologies. Master the
                      intricacies of .NET core and ASP.NET backend development.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/.Net-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/mern.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">MERN FullStack Developer</a>
                    </h4>
                    <p>
                      You will learn to build dynamic, scalable web applications
                      using MongoDB, Express.js, React, and Node.js
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/MERN-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/Java-Full-Stack.png"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">Full Stack Java Developer</a>
                    </h4>
                    <p>
                      Streamline your Java application development with
                      simplicity, speed, and robustness for modern, scalable
                      projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/JAVA-Full-Stack-Course-Details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      src="assets/images/courses/devops.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="">DevOps Advanced Certification</a>
                    </h4>
                    <p>
                      The program is designed by industry experts to help you
                      master AWS, cloud computing, VPC, etc., through real-time
                      hands-on projects.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/DevOps-Course-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>

              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/ui-ux.png"
                      src="assets/images/courses/vlsi-physical-design.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI PHYSICAL DESIGN</a>
                    </h4>
                    <p>
                    Be that Physical Designer with the world-class industry-oriented VLSI – Physical Design training program using the Cadence Encounter tool.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-PHYSICAL-DESIGN-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-PHYSICAL-DESIGN-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/react-front-end.jpg"
                      src="assets\images\courses\vlsi-analog-layout.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI ANALOG LAYOUT</a>
                    </h4>
                    <p>
                    Be that Analog Layout Designer with the world-class industry-oriented training program using the Cadence Virtuoso tool.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-ANALOG-LAYOUT-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-ANALOG-LAYOUT-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/react-native.jpg"
                      src="assets\images\courses\VLSI-Design-Verification.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI Design Verification</a>
                    </h4>
                    <p>
                    Bridging innovation with precision through industry-grade VLSI Design Verification training using Cadence & Mentor Graphics tools.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link
                      to="/VLSI-design-verification-details"
                      className="class_link"
                    >
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link
                      to="/VLSI-design-verification-details"
                      className="class_link"
                    >
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="col-lg-3 col-md-6">
                <div className="class_box">
                  <div className="class_Img">
                    <img
                      // src="assets/images/courses/angular.jpg"
                      src="assets\images\courses\VLSI-Embedded-Systems.jpg"
                      alt=""
                      style={{
                        objectFit: "cover",
                        height: "120px",
                        borderRadius: "12px 12px 0 0",
                      }}
                    />
                  </div>
                  <div className="path_box">
                    <h4>
                      <a href="#">VLSI Embedded Systems</a>
                    </h4>
                    <p>
                    Bring the change by joining the world-class industry-oriented Embedded Systems training program offered by ROUTE2SOC.
                    </p>
                  </div>
                  <div className="row ml-1 pb-2">
                    <Link to="/VLSI-Embedded-Systems-Details" className="class_link">
                      <div className="viewmore p-4 m-1 ">
                        <a href="">View More</a>
                      </div>
                    </Link>
                    <Link to="/VLSI-Embedded-Systems-Details" className="class_link">
                      <div className="booknow p-4 m-1 ">
                        <a href="">3 months</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Training;
