import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Blog/Header/Header";
import Footer from "../../Blog/Footer/Footer";
import Sidebar from "../components/Sidebar";

const Courses = () => {
  return (
    <>
      <Header />
      <div className='d-flex'>
      <Sidebar/>
      <div className="container-fluid">
        <div class="container mt-5 mb-2">
          <div class="row">
            <div class="col">
              <h2>Courses</h2>
            </div>
            <div class="col-auto">
              <Link to="/home-add-courses" class="class_link">
                <div class="viewmore p-4 m-1">
                  <a href="">Add Courses</a>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td colspan="2">Larry the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Courses;
