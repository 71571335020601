import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import OwlCarousel from "react-owl-carousel2";
import Clients from "../Clients/Clients";

const Jobseekers = () => {
  return (
    <>
      <Header />
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            Are you looking for a job?
                          </h2>
                          <p style={{ color: "black" }}>
                            We’re to help! explore our tips and resources and
                            get closer to your dream job, step-by-step. find
                            exciting job opportunities in the professional
                            fields.<br></br>
                            <br></br>Simply send us your application using the
                            application form and Ideants will find the right job
                            for you.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Explore All Jobs</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Submit Your Resume</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        // boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/jobseekers.jpg"></img>
                      {/* <form
                        // method="post"
                        // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                        id="contact-form"
                        class="default-form form-group"
                      >
                        <div class=" clearfix">
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="username"
                              placeholder="Your Name *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="email"
                              // name="email"
                              placeholder="Email Address *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="phone"
                              required=""
                              placeholder="Phone *"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="subject"
                              required=""
                              placeholder="Subject"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                            <button
                              class="theme-btn-one"
                              type="submit"
                              // name="submit-form"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- solutions-problems --> */}
      <section className="solutions-problems mt-5">
        <div className="auto-container">
          <div className="carousel-box">
            <h2 style={{ textAlign: "center", fontSize: "42px" }}>
              advance your career now!
            </h2>
            <OwlCarousel
              className="two-column-carousel owl-carousel owl-theme owl-dot-style-two owl-nav-none"
              items={2}
              loop
              autoplay
              nav
              dots
              margin={10} // Adjust the margin as needed
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 2,
                },
              }}
            >
              <div className="single-item">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src="assets/images/resource/solutions-1.jpg" alt="" />
                  </figure>
                  <div className="lower-content">
                    <h3>Discover professions from A to Z</h3>
                  </div>
                  <div className="side-content">
                    <div className="content-box">
                      <span>01.</span>
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-56.png" alt="" />
                      </figure>
                    </div>
                    <div className="overlay-box">
                      <span>01.</span>
                      <a href="">
                        <i className="flaticon-right-arrow-angle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src="assets/images/resource/solutions-1.jpg" alt="" />
                  </figure>
                  <div className="lower-content">
                    <h3>find a job that is a perfect match for you!</h3>
                  </div>
                  <div className="side-content">
                    <div className="content-box">
                      <span>02.</span>
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-56.png" alt="" />
                      </figure>
                    </div>
                    <div className="overlay-box">
                      <span>02.</span>
                      <a href="">
                        <i className="flaticon-right-arrow-angle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="inner-box">
                  <figure className="image-box">
                    <img src="assets/images/resource/solutions-1.jpg" alt="" />
                  </figure>
                  <div className="lower-content">
                    <h3>Job Interview & Employment Contract</h3>
                  </div>
                  <div className="side-content">
                    <div className="content-box">
                      <span>03.</span>
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-56.png" alt="" />
                      </figure>
                    </div>
                    <div className="overlay-box">
                      <span>03.</span>
                      <a href="">
                        <i className="flaticon-right-arrow-angle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* <!-- solutions-problems end --> */}
      <Clients />
      {/* <!-- findjob-section --> */}
      <section class="findjob-section">
        <div class="auto-container">
          <div class="sec-title centred">
            <span class="top-title">Recently Posted Jobs</span>
            <h2>Find Your Job You Deserve It</h2>
            <p>
              Long established fact that a reader will be distracted by the{" "}
              <br />
              readable content of a page.
            </p>
          </div>
          <div class="search-inner">
            <form
              // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/index.html"
              // method="post"
              class="search-form"
            >
              <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12 column">
                  <div class="form-group">
                    <i class="flaticon-search"></i>
                    <input
                      type="search"
                      // name="search-field"
                      placeholder="Keyword (Title or Company)"
                      required=""
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 column">
                  <div class="form-group">
                    <i class="flaticon-place"></i>
                    <input
                      type="search"
                      // name="search-field"
                      placeholder="Location (City or State)"
                      required=""
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 column">
                  <div class="form-group">
                    <i class="flaticon-clock"></i>
                    <input
                      type="search"
                      // name="search-field"
                      placeholder="Enter Experience"
                      required=""
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 column">
                  <div class="form-group message-btn">
                    <button type="submit" class="theme-btn-one">
                      Search Jobs
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="post-jobs">
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="direct-hire">Direct Hire</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 2 Hrs ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2022ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-1.png" alt="" />
                  </figure>
                  <h3>Human Resources Manager</h3>
                  <p>
                    <i class="flaticon-place"></i>Sunnyvale, California
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$44,000 - $55,000 Per Year</p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>1-3 Yrs</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="">Contract to Hire</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 5 Hrs ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2021ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-2.png" alt="" />
                  </figure>
                  <h3>Software Engineer</h3>
                  <p>
                    <i class="flaticon-place"></i>San Fransisco, California
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$85,000 - $90,000 Per Year</p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>3-5 Yrs</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="">Contract</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 1 Day ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2020ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-3.png" alt="" />
                  </figure>
                  <h3>Administrative Coordinator</h3>
                  <p>
                    <i class="flaticon-place"></i>Boston, Massachusetts
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$62,000 - $75,000 Per Year</p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>Freshers</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="">Contract to Hire</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 2 Days ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2018ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-4.png" alt="" />
                  </figure>
                  <h3>Talent Acquisition Specialist</h3>
                  <p>
                    <i class="flaticon-place"></i>San Fransisco, California
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$85,000 - $90,000 Per Year</p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>3-5 Yrs</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="">Direct Hire</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 2 Hrs ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2022ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-5.png" alt="" />
                  </figure>
                  <h3>Human Resources Manager</h3>
                  <p>
                    <i class="flaticon-place"></i>Sunnyvale, California
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$44,000 - $55,000 Per Year </p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>3-5 Yrs</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
            <div class="single-job-post">
              <div class="job-header clearfix">
                <ul class="info pull-left">
                  <li>
                    <a href="">Contract to Hire</a>
                  </li>
                  <li>
                    <i class="flaticon-clock"></i>Posted 5 Hrs ago
                  </li>
                </ul>
                <div class="number pull-right">
                  <p>Job Num: 2021ER</p>
                </div>
              </div>
              <div class="job-inner clearfix">
                <div class="job-title">
                  <figure class="company-logo">
                    <img src="assets/images/resource/logo-6.png" alt="" />
                  </figure>
                  <h3>Software Engineer</h3>
                  <p>
                    <i class="flaticon-place"></i>San Fransisco, California
                  </p>
                </div>
                <div class="salary-box">
                  <span>Salary</span>
                  <p>$85,000 - $90,000 Per Year</p>
                </div>
                <div class="experience-box">
                  <span>Experience Need</span>
                  <p>3-5 Yrs</p>
                </div>
                <div class="apply-btn">
                  <a href="">Apply</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- findjob-section end --> */}

      {/* <!-- growth-style-two --> 
      <section class="growth-style-two bg-color-2">
        <div
          class="image-column"
          style={{
            backgroundImage: "url(assets/images/background/growth-bg.jpg)",
          }}
        ></div>
        <div class="auto-container">
          <div class="row align-items-center clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
              <div id="content_block_1">
                <div class="content-box">
                  <div class="sec-title">
                    <span class="top-title">Time to grow</span>
                    <h2>Let Us Look For You</h2>
                  </div>
                  <div class="inner-box">
                    <div class="text">
                      <p>
                        Relax as we diligently search for the perfect career
                        match tailored to your skills, aspirations, and
                        preferences, ensuring a seamless transition to your next
                        professional endeavor.
                      </p>
                      <p>
                        Sit back and let our expert team search for the ideal
                        career opportunity that aligns with your goals and
                        aspirations, ensuring a smooth and successful job search
                        journey.
                      </p>
                    </div>
                    <ul class="list clearfix">
                      <li>
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-66.png" alt="" />
                        </figure>
                        <h3>Understand Your Needs</h3>
                      </li>
                      <li>
                        <figure class="icon-box">
                          <img src="assets/images/icons/icon-67.png" alt="" />
                        </figure>
                        <h3>Find the Perfect Candidate</h3>
                      </li>
                    </ul>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>Job Opportunities
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
              <div class="text">
                <h2>There is a way to do it better…find</h2>
                <p>There's always a better way; let's find it together.</p>
                <a href="" class="theme-btn-two">
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
        <!-- growth-style-two --> */}

      {/* <!-- advantages-section --> 
      <section class="advantages-section">
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-46.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="sec-title centred">
            <span class="top-title">Benefits of Ideants Technologies</span>
            <h2>Advantages of Working With Us</h2>
            <p>
              Experience the difference of working with a dedicated team
              committed to your growth and success. Tailored solutions, expert
              guidance, and unparalleled support for your success.
            </p>
          </div>
          <div class="four-item-carousel owl-carousel owl-theme owl-nav-none owl-dot-style-two">
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-44.png" alt="" />
                </figure>
                <h3>Certified Companies</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-45.png" alt="" />
                </figure>
                <h3>Save Your Time</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-46.png" alt="" />
                </figure>
                <h3>Save Your Money</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-47.png" alt="" />
                </figure>
                <h3>Broad Network</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-44.png" alt="" />
                </figure>
                <h3>Certified Companies</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-45.png" alt="" />
                </figure>
                <h3>Save Your Time</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-46.png" alt="" />
                </figure>
                <h3>Save Your Money</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-47.png" alt="" />
                </figure>
                <h3>Broad Network</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-44.png" alt="" />
                </figure>
                <h3>Certified Companies</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-45.png" alt="" />
                </figure>
                <h3>Save Your Time</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-46.png" alt="" />
                </figure>
                <h3>Save Your Money</h3>
                <p>Indignation and dislike men who beguiled demoralized.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
            <div class="single-item">
              <div class="inner-box">
                <figure class="icon-box">
                  <img src="assets/images/icons/icon-47.png" alt="" />
                </figure>
                <h3>Broad Network</h3>
                <p>Business it will frequently occur pleasures repudiated.</p>
                <a href="">
                  <i class="flaticon-right-arrow"></i>More Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
       <!-- advantages-section end --> */}

      {/* <!-- hiring-section --> *
      <section class="hiring-section">
        <div class="image-layer">
          <figure class="image-1">
            <img src="assets/images/resource/hiring-1.png" alt="" />
          </figure>
          <figure class="image-2">
            <img src="assets/images/resource/hiring-2.png" alt="" />
          </figure>
        </div>
        <div class="outer-container clearfix">
          <div class="left-column pull-left clearfix">
            <div class="inner-box pull-right">
              <div class="icon-box">
                <i class="flaticon-factory"></i>
              </div>
              <h2>Industries Hiring</h2>
              <p>
                Find fault with a man who chooses to enjoy a pleasure that has
                no annoying consequences.
              </p>
              <a href="">Industries</a>
            </div>
          </div>
          <div class="right-column pull-right clearfix">
            <div class="inner-box pull-left">
              <div class="icon-box">
                <i class="flaticon-working-man"></i>
              </div>
              <h2>Professions Hiring</h2>
              <p>
                Chooses to enjoy a pleasure that has no annoying one who avoids
                a pain that produces.
              </p>
              <a href="">Professions</a>
            </div>
          </div>
        </div>
      </section>
       <!-- hiring-section end --> */}

      {/* <!-- contact-section --> */}

      {/* <!-- contact-section end --> */}

      <Footer />
    </>
  );
};

export default Jobseekers;
