import React from 'react'

const Errorpage = () => {  
  return (
    <div>
      {/* <!-- error-section --> */}
        <section class="error-section centred">
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-1 content-column">
                        <div class="error-content">
                            <figure class="image-box"><img src="assets/images/icons/error-img.png" alt=""/></figure>
                            <h2>Sorry this page isn’t available</h2>
                            <p>We're not being able to find the page you're looking for</p>
                            <a href="/" class="theme-btn-two">Back to Home</a>
                        </div>
                    </div>
                </div> 
            </div>
        </section> 
        {/* <!-- error-section end --> */}
    </div>
  )
}

export default Errorpage 