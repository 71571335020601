import React from "react";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import { Link } from "react-router-dom";

const VLSIdesignverification = () => {
  return (
    <div>
      <Header />
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                            VLSI Design Verification
                          </h2>
                          <p style={{ color: "white", fontWeight: "200" }}>
                            VLSI design verification involves the validation of
                            the logical correctness, functional accuracy, and
                            performance of an integrated circuit design before
                            fabrication. It aims to detect and rectify design
                            errors, bugs, and discrepancies to prevent costly
                            re-spins and ensure the reliability of the final
                            product.VLSI design verification is a multi-faceted
                            process that encompasses various techniques,
                            methodologies, and tools to ensure the correctness,
                            reliability, and performance of complex integrated
                            circuit designs in the semiconductor industry.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/VLSI-Design-Verification.jpg"></img>
                      {/* <form
                    // method="post"
                    // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                    id="contact-form"
                    class="default-form form-group"
                  >
                    <div class=" clearfix">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                        <input
                          type="text"
                          // name="username"
                          placeholder="Your Name *"
                          required=""
                        />
                      </div>
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                        <input
                          type="email"
                          // name="email"
                          placeholder="Email Address *"
                          required=""
                        />
                      </div>
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                        <input
                          type="text"
                          // name="phone"
                          required=""
                          placeholder="Phone *"
                        />
                      </div>
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                        <input
                          type="text"
                          // name="subject"
                          required=""
                          placeholder="Subject"
                        />
                      </div>
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- React Course -details --> */}
      <section class="job-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="job-details-content">
                <div class="text">
                  <h2>Course Description</h2>
                  <p>
                    Basics of RC circuits; Charging and discharging; governing
                    equations and their explanation; rise time and fall time, RC
                    time constant, Elmore delay model in distributed RC network;
                    concept of drive strength, contention, tri-state and noise
                    margin. Practice problems.Sign-magnitude (SM), 1’s
                    complement, 2’s complement(TC); addition of 2’complement
                    numbers, Logical and arithmetic shifting, sign extension,
                    binary fractions; Practice problems in logic circuits
                    related to binary number systems. Combinational circuits:
                    Karnaugh map, De-Morgan’s theorems, Multiplexer as a
                    universal element, de-multiplexers, using multiplexers for
                    constructing other logic gates – N/AND, N/OR, XOR, XNOR,
                    NOT, Buffer. Practice problems in combinational logic
                  </p>
                  <p>
                    Working of NMOS FET from first principles. Concept of
                    threshold voltage; cutoff region, linear regin and
                    saturation region. Transmission of an imperfect 1/0 and
                    perfect 1/0; Building logic gates using MOS transistors as
                    transmission switches. Construction of 2-i/p NAND, NOR, AND,
                    OR gates, and inverter. Voltage transfer characteristic
                    (VTC) of an inverter.{" "}
                  </p>
                  {/* <h3>Responsibilities</h3>
              <ul class="list clearfix">
                <li>
                Developing user interface components using React.js concepts and best practices.
                </li>
                <li>
                Translating UI/UX design wireframes into high-quality code.
                </li>
                <li>
                Optimizing components for maximum performance across various browsers and devices.
                </li>
                <li>
                Collaborating with back-end developers to integrate front-end components with server-side logic.
                </li>
              </ul>
              <h3>Skills</h3>
              <ul class="list clearfix">
                <li>
                Proficiency in HTML, CSS, and JavaScript.
                </li>
                <li>
                Strong understanding of UI/UX design principles.
                </li>
                <li>
                Experience with React.js and its ecosystem (React Router, Redux, etc.).
                </li>
                <li>
                Ability to create responsive and mobile-friendly interfaces.
                </li>
                <li>
                Familiarity with version control systems (e.g., Git).
                </li>
              </ul> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar">
                <div
                  class=" form-column"
                  style={{
                    boxShadow: "0px 0px 1px 1px lightblue",
                    borderRadius: "10px",
                    borderTop: "10px solid #662d91",
                    paddingBottom: "3px",
                  }}
                >
                  <div class="form-inner" style={{ margin: "20px" }}>
                    <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form"
                    >
                      <div class=" clearfix">
                        <h4 style={{ textAlign: "center" }}>
                          Free Career Counselling
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          We are happy to help you 24/7
                        </p>

                        <div class=" form-group mt-3">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>

                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Email Id *"
                          />
                        </div>
                        {/* <div class=" form-group">
                  <input
                    type="text"
                    // name="location"
                    placeholder="Location "
                  />
                </div>
                <div class=" form-group">
                  <input
                    type="text"
                    // name="subject"
                    required=""
                    placeholder="Subject"
                  />
                </div>
                <div class=" form-group">
                  <textarea
                    // name="message"
                    placeholder="Message ..."
                  ></textarea>
                </div> */}
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <div class="sidebar-widget support-widget mt-5">
              <div class="widget-content centred">
                <div class="pattern-layer">
                  <div
                    class="pattern-1"
                    style={{
                      backgroundImage:
                        "url(assets/images/shape/pattern-44.png)",
                    }}
                  ></div>
                  <div
                    class="pattern-2"
                    style={{
                      backgroundImage:
                        "url(assets/images/shape/pattern-45.png)",
                    }}
                  ></div>
                </div>
                <figure class="image-box">
                  <img src="assets/images/resource/author-2.png" alt="" />
                </figure>
                <h3>Shahid</h3>
                <span class="designation">Trainer</span>
                <ul class="support-info">
                  <li>
                    Call: <a href="tel:8004561234">(800) 456-1234</a>
                  </li>
                  <li>
                    Mail:{" "}
                    <a href="mailto:baldwin@example.com">
                      shahid@gmail.com
                    </a>
                  </li>
                </ul>
                <ul class="social-links">
                  <li>
                    <a href="job-details.html">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="job-details.html">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="job-details.html">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 column">
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Why VLSI Design Verification Training from Ideants
                </h2>
              </div>
            </div>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-130px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Live Interactive Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> World-Class
                            Instructors
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Expert-Led
                            Mentoring Sessions
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Instant doubt
                            clearing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Lifetime Access</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Access
                            Never Expires
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Free Access to
                            Future Updates
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Unlimited
                            Access to Course Content
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>24x7 Live & desk Support.</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> One-On-One
                            Learning Assistance
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Help Desk
                            Support
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Resolve Doubts
                            in Real-time
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                    <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                            <h3>Broad Network</h3>
                            <p>Business it will frequently pleasures repudiated.</p>
                            <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                        </div>
                    </div>
                </div> */}
                </div>
              </div>
            </section>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-240px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Hands-On Project Based Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>{" "}
                            Industry-Relevant Projects
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Demo
                            Dataset & Files
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Quizzes &
                            Assignments
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Industry Recognised Certification</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Ideants
                            Training Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Graded
                            Performance Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Certificate of
                            Completion
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>Opportunity in Top MNCs</h3>
                        <li>
                          <i class="flaticon-right-arrow"></i>
                          VLSI - Digital Verification ASIC Engineer
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i>
                          Design & Verification Engineer
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i>
                          Design Verification Engineer
                        </li>
                        <br></br>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                    <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                            <h3>Broad Network</h3>
                            <p>Business it will frequently pleasures repudiated.</p>
                            <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                        </div>
                    </div>
                </div> */}
                </div>
              </div>
            </section>
            <div
              class="col-lg-12 col-md-12 col-sm-12 column"
              style={{ marginTop: "-50px" }}
            >
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Instructor-led VLSI Design Verification live online Training
                  Schedule & Flexible batches for you
                </h2>
              </div>
            </div>
            <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        {/* <h4>
                        Price: ₹
                        <span style={{ textDecoration: "line-through" }}>
                          29,999
                        </span>
                      </h4> */}
                        <h1>₹80,000/- [+ 18% GST].</h1>
                        {/* <p>17% OFF , Save ₹5000.</p> */}
                        <br></br>
                        {/* <h2>Duration: 45 Days</h2> */}
                        <h4>May 22 - Orientation Class</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                              VLSI Design Verification Certification Training
                              Course
                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Introduction to VLSI Design Verification
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>Digital
                                    Logic and RTL Design Basics
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>
                                    Functional Verification Techniques
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Assertion-Based Verification (ABV)
                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Code
                                    and Functional Coverage Analysis
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Advanced Verification Techniques
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>Timing
                                    and Power Verification
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Post-Silicon Validation and Debugging
                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>Industry
                                    Standards and Best Practices
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="about-section sec-pad"
              style={{ marginTop: "-190px", width: "100%" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-12 col-sm-12 content-column"
                      style={{ borderRight: "2px solid lightgrey" }}
                    >
                      <div id="content_block_2" style={{ padding: "30px" }}>
                        <h3 style={{ textAlign: "center", fontSize: "36px" }}>
                          Skills Covered
                        </h3>
                        <hr></hr>

                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Digital Design
                            Fundamentals
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Verification
                            Methodologies
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Testbench
                            Development
                          </li>
                          <li>
                            {" "}
                            <i class="flaticon-right-arrow"></i> Formal
                            Verification
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Coverage
                            Analysis
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Timing and
                            Power Verification
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_3">
                      <div class="content-box">
                        <div class="sec-title">
                          <h3
                            style={{ textAlign: "center", fontSize: "36px" }}
                          >
                            Tools Covered
                          </h3>
                          <hr></hr>
                          <img src="assets/images/courses/react.png"></img>
                          <img src="assets/images/courses/redux.png"></img>
                          <img src="assets/images/courses/graphql.png"></img>
                          <img src="assets/images/courses/javascript.png"></img>
                          <img src="assets/images/courses/jest.png"></img>
                          <img src="assets/images/courses/git.png"></img>
                          <img src="assets/images/courses/docker.png"></img>
                          <img src="assets/images/courses/nginx.png"></img>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Short term Internship Program Details
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Long term Internship Program Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="faq-section text-left"
              style={{ width: "100%", marginTop: "-20px" }}
            >
              <div class="container-fluid text-left" style={{ width: "100%" }}>
                <div class="row">
                  <div class="col-md-8">
                    <section
                      class="btc-accordion text-left"
                      style={{ width: "100%" }}
                    >
                      <div
                        class="container text-left"
                        style={{ width: "100%" }}
                      >
                        <h1 class="text-center text-black">
                          VLSI Design Verification Certification Training Course
                          Curriculum
                        </h1>
                        <br />
                        <p class="text-center text-black">
                          <h4>Curriculum Designed by Experts</h4>{" "}
                        </p>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="accordion mb-2" id="accordionExample">
                              <div class="card">
                                <div
                                  class="card-header collapsed"
                                  id="headingOne"
                                >
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      1. Introduction to VLSI (5 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            VLSI Design Flow
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ASIC vs FPGA{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL Design Methodologies
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to ASIC Verification
                                            Methodologies
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            VLSI Design Flow Steps - Demo
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Designing integrated circuits
                                              using tools like Verilog and CAD
                                              software.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Mastering circuit design, layout
                                              creation, simulation, and
                                              fabrication processes in VLSI
                                              enables the development of
                                              advanced electronic systems and
                                              devices.{" "}
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Basic Semiconductor Physics
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Digital Logic Design
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Integrated Circuit (IC)
                                              Fabrication
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      2. Introduction to Linux (4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Components of the UNIX system{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Directory Structure
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Utilities and Commands{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Vi Editor
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              It provides a Unix-like
                                              environment with a powerful
                                              command-line interface, support
                                              for multi-user and multitasking
                                              capabilities, and a vast ecosystem
                                              of free and open-source software.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Open-Source Kernel
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Modularity and Customization
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Stability and Reliability{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingThree">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <h5 class="py-2">
                                      3. Advanced Digital Design(10 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  aria-labelledby="headingThree"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Digital Electronics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arithmetic Circuits
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Data processing Circuits
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Universal Logic Elements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Combinational Circuits - Design and
                                            Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Latches and Flip flops
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Shift Registers and Counters
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sequential Circuits - Design and
                                            Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Memories and PLD
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Finite State Machine
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Advanced Digital Design delves
                                              into complex digital system
                                              architectures, focusing on
                                              optimization, performance
                                              enhancement, and integration of
                                              advanced features.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              High-Speed Design Techniques
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFour">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <h5 class="py-2">
                                      4. Verilog HDL - RTL Coding and Synthesis
                                      (7 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Verilog HDL{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Data Types
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Verilog Operators{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Assignments{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Finite State Machine{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Advanced Verilog for Verification{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Synthesis Coding{" "}
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Verilog Hardware Description
                                              Language (HDL) is commonly used
                                              for Register Transfer Level (RTL)
                                              coding, allowing designers to
                                              describe digital circuits at a
                                              high level.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              RTL code describes the behavior of
                                              a circuit in terms of data
                                              transfers between registers,
                                              making it suitable for synthesis
                                              tools to generate gate-level
                                              implementations for hardware
                                              realization.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              RTL Coding
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Synthesis{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Design Constraints{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingFive">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    <h5 class="py-2">
                                      5. Static Timing Analysis (8 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  class="collapse"
                                  aria-labelledby="headingFive"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to STA
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Comparison with DTA
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timing Path and Constraints{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Different types of clocks
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock domain and Variations{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Clock Distribution Networks{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to fix timing failure
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Methods to improve timing
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Static Timing Analysis (STA) is a
                                              method used to verify the timing
                                              performance of a digital circuit
                                              without the need for simulation.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Timing Constraints
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Path Analysis{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Timing Verification{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Optimization{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingSix">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                  >
                                    <h5 class="py-2">
                                      6.FPGA Architecture(4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSix"
                                  class="collapse"
                                  aria-labelledby="headingSix"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            PLD{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Xilinx CPLD - Xc9500{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Xilinx FPGA
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            FPGA Architecture of Different
                                            Xilinx Families{" "}
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              FPGA (Field-Programmable Gate
                                              Array) architecture consists of an
                                              array of configurable logic blocks
                                              (CLBs), interconnect resources,
                                              and I/O blocks.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Configurable Logic Blocks (CLBs){" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Interconnects{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              I/O Blocks (IOBs){" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Clock Management Resources{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSeven">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                  >
                                    <h5 class="py-2">
                                      7.Code Coverage(6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSeven"
                                  class="collapse"
                                  aria-labelledby="headingSeven"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Statement coverage{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Branch Coverage
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Expression Coverage
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Path Coverage
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Toggle Coverage
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            FSM - State, Transition coverage
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Code coverage is a metric used in
                                              software testing to measure the
                                              extent to which source code has
                                              been exercised by a test suite.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Statement Coverage{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Branch Coverage{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Path Coverage{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEight">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                  >
                                    <h5 class="py-2">
                                      8.Verilog Mini Project RTL Coding and
                                      Synthesis (4 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEight"
                                  class="collapse"
                                  aria-labelledby="headingEight"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Project Specification Analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Understanding the architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Module-level implementation and
                                            verification
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Building the top-level module
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Implementing a mini project in
                                              Verilog involves coding the
                                              desired functionality using RTL
                                              (Register Transfer Level)
                                              descriptions.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Project Definition{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              RTL Coding
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Simulation{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingNine">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                  >
                                    <h5 class="py-2">
                                      9.Design Automation using Scripts Perl ( 6
                                      topics ){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseNine"
                                  class="collapse"
                                  aria-labelledby="headingNine"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Perl
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Functions and Statements
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Numbers, Strings, and Quotes
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Comments and Loops
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Regular Expressions
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            File Operations
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Design automation using Perl
                                              scripts involves developing
                                              scripts to automate various
                                              aspects of the design process,
                                              such as RTL generation, synthesis,
                                              simulation, and verification.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Task Automation{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Tool Integration{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Data Processing and Analysis{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                  >
                                    <h5 class="py-2">
                                      10.CMOS Fundamentals (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTen"
                                  class="collapse"
                                  aria-labelledby="headingTen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Non-Ideal characteristics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            BJT vs FET
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Characteristics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS circuit design
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Fabricaion Process Overview{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Transistor sizing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Layout and Stick Diagrams
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Technology - Current Trends
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CMOS Processing Steps
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              CMOS (Complementary
                                              Metal-Oxide-Semiconductor) is a
                                              semiconductor technology widely
                                              used in integrated circuits due to
                                              its low power consumption and high
                                              noise immunity.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Transistor Structure{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Operation Principle{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Advantages and Applications{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEleven">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                  >
                                    <h5 class="py-2">
                                      11.Static Timing Analysis (Self-Paced)(7
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEleven"
                                  class="collapse"
                                  aria-labelledby="headingEleven"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Directed Vs Random
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Functional verification process
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Monitors and reference models
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Stimulus Generation
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Verification Planning and management
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Bus functional model
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Coverage Driven Verification
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              ASIC (Application-Specific
                                              Integrated Circuit) verification
                                              methodologies are systematic
                                              approaches used to ensure the
                                              correctness and functionality of
                                              ASIC designs.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Simulation-Based Verification{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Hardware Emulation and Prototyping{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Formal Verification{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTwelve">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwelve"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelve"
                                  >
                                    <h5 class="py-2">
                                      12.SystemVerilog HVL (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwelve"
                                  class="collapse"
                                  aria-labelledby="headingTwelve"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to SystemVerilog{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Object Oriented Programming and
                                            Randomization
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Threads and Virtual Interfaces{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Callbacks{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Direct Programming Interface
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Functional Coverage
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              SystemVerilog HVL is an extension
                                              of the Verilog hardware
                                              description language, designed
                                              specifically for verification
                                              purposes.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Constrained Randomization{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Object-Oriented Programming (OOP){" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Coverage Driven Verification (CDV){" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingThirteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                  >
                                    <h5 class="py-2">
                                      13.Advanced SystemVerilog (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThirteen"
                                  class="collapse"
                                  aria-labelledby="headingThirteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Environment Configuration{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Reference Models and Predictor
                                            Logics
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Using Legacy BFMs
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Scenario Generation
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Testcases - Random, Directed and
                                            corner case
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Coding styles for VIP
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Advanced SystemVerilog builds upon
                                              the basic Verilog language and
                                              adds powerful features tailored
                                              for design and verification.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Design Constructs{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Verification Constructs{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Assertion-Based Verification (ABV){" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFourteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFourteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFourteen"
                                  >
                                    <h5 class="py-2">
                                      14. UVM - Universal Verification
                                      Methodology (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFourteen"
                                  class="collapse"
                                  aria-labelledby="headingFourteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to UVM Methodology
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Overview of Project
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            UVM TB Architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Stimulus Modeling
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Creating UVCs and Environment
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            UVM Simulation Phases
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            TLM Overview
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Configuring TB Environment
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            UVM Sequencers
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              UVM is a standardized methodology
                                              for verifying integrated circuit
                                              designs using SystemVerilog.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Component-Based Architecture{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Transaction-Level Modeling (TLM){" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Standardized Methodology{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFifteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFifteen"
                                    aria-expanded="false"
                                    aria-controls="collapseFifteen"
                                  >
                                    <h5 class="py-2">
                                      15.Verification Mini Project (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFifteen"
                                  class="collapse"
                                  aria-labelledby="headingFifteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Verification and RTL sign-off
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Project specification analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Defining verification plan
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Creating Testbench architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            How to analyse the synthesis report?
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Implementing the transactors -
                                            Generator, Driver, Receiver, and
                                            Scoreboard{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Defining Transaction{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Implementing the coverage model
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tool related Technology files
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              A verification mini project
                                              typically involves developing and
                                              verifying a small digital design
                                              or module using a specific
                                              verification methodology, such as
                                              UVM.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Simulation and Debugging{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Coverage Analysis{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Documentation and Presentation{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSixteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSixteen"
                                    aria-expanded="false"
                                    aria-controls="collapseSixteen"
                                  >
                                    <h5 class="py-2">
                                      16. Interfaces and Protocols (1 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSixteen"
                                  class="collapse"
                                  aria-labelledby="headingSixteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Lectures by Industry Experts
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Interfaces define the
                                              communication boundaries between
                                              different components or systems,
                                              specifying the signals, protocols,
                                              and behaviors necessary for
                                              interaction.{" "}
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Physical Interfaces{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Logical Interfaces
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Standardized Interfaces{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingSeventeen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseSeventeen"
                                    aria-expanded="false"
                                    aria-controls="collapseSeventeen"
                                  >
                                    <h5 class="py-2">
                                      17. Verification Planning and Management
                                      (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseSeventeen"
                                  class="collapse"
                                  aria-labelledby="headingSeventeen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Verification Plan
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            TB Architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Coverage Model
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tracking the simulation process
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Building regression test suite
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Testsuite optimization
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Verification planning and
                                              management involve defining the
                                              verification goals, strategies,
                                              and resources required to ensure
                                              the correctness and functionality
                                              of a digital design.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Documentation and Reporting{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Verification Execution{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Documentation and Reporting{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingEighteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseEighteen"
                                    aria-expanded="false"
                                    aria-controls="collapseEighteen"
                                  >
                                    <h5 class="py-2">
                                      18. Assertion Based Verification - SVA (6
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseEighteen"
                                  class="collapse"
                                  aria-labelledby="headingEighteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to ABV
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Immediate Assertions
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Simple Assertions
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sequences{" "}
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sequence Composition
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Assertion Coverage
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              ABV involves using formal
                                              assertions expressed in
                                              SystemVerilog Assertion (SVA)
                                              language to specify and verify
                                              desired properties or behaviors in
                                              a digital design.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Clarity and Conciseness{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              SVA Constructs{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Temporal Logic{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingNineteen">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseNineteen"
                                    aria-expanded="false"
                                    aria-controls="collapseNineteen"
                                  >
                                    <h5 class="py-2">
                                      19. Business communication (6 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseNineteen"
                                  class="collapse"
                                  aria-labelledby="headingNineteen"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            The transition from College to
                                            Corporate
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Interpersonal skills and
                                            Presentation Skills
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Email Etiquette
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Resume writing
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Mockup Interviews Technical/HR
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Interview Skills: Group Discussion
                                            and HR Round Preparation
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Signal integrity refers to the
                                              quality and reliability of
                                              electrical signals in a circuit,
                                              while cross-talk issues occur when
                                              unwanted interference from
                                              neighboring signals degrades
                                              signal quality, affecting circuit
                                              performance.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Signal Integrity{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Crosstalk{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Mitigation Techniques{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingTwenty">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwenty"
                                    aria-expanded="false"
                                    aria-controls="collapseTwenty"
                                  >
                                    <h5 class="py-2">
                                      20. Industry Standard Projects (9 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwenty"
                                  class="collapse"
                                  aria-labelledby="headingTwenty"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Design specification analysis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Creating the design architecture
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Partitioning the design
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL coding in Verilog
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL functional verification
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            RTL Synthesis
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Place & Route the netlist
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Static Timing Analysis & ECO
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Physical Verification & Signoff
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Industry standard projects adhere
                                              to established protocols,
                                              guidelines, and best practices to
                                              ensure compatibility, reliability,
                                              and performance across various
                                              domains in engineering and
                                              technology.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Adherence to Standards{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Best Practices{" "}
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Collaboration and Tool Usage{" "}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="col-md-4">
                    <div
                      class=" form-column"
                      style={{
                        boxShadow: "0px 0px 1px 1px lightblue",
                        borderRadius: "10px",
                        borderTop: "10px solid #662d91",
                        paddingBottom: "3px",
                        width: "100%",
                        marginTop: "155px",
                      }}
                    >
                      <div class="form-inner" style={{ margin: "20px" }}>
                        <form
                          // method="post"
                          // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                          id="contact-form"
                          class="default-form"
                        >
                          <div class=" clearfix">
                            <h4 style={{ textAlign: "center" }}>
                              Free Career Counselling
                            </h4>
                            <p style={{ textAlign: "center" }}>
                              We are happy to help you 24/7
                            </p>

                            <div class=" form-group mt-3">
                              <input
                                type="text"
                                // name="username"
                                placeholder="Your Name *"
                                required=""
                              />
                            </div>

                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Phone *"
                              />
                            </div>
                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Email Id *"
                              />
                            </div>
                            {/* <div class=" form-group">
                  <input
                    type="text"
                    // name="location"
                    placeholder="Location "
                  />
                </div>
                <div class=" form-group">
                  <input
                    type="text"
                    // name="subject"
                    required=""
                    placeholder="Subject"
                  />
                </div>
                <div class=" form-group">
                  <textarea
                    // name="message"
                    placeholder="Message ..."
                  ></textarea>
                </div> */}
                            <div class=" form-group message-btn centred">
                              <button
                                class="theme-btn-one"
                                type="submit"
                                // name="submit-form"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <div class="col-lg-12 col-md-12 col-sm-12 column">
          <div class="related-job">
            <h2>Our Trained Students</h2>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar" style={{display:"flex"}}>
              
                <div class="sidebar-widget support-widget">
                  <div class="widget-content centred m-4">
                    <div class="pattern-layer">
                      <div
                        class="pattern-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-44.png)",
                        }}
                      ></div>
                      <div
                        class="pattern-2"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-45.png)",
                        }}
                      ></div>
                    </div>
                    <figure class="image-box">
                      <img
                        src="assets/images/resource/author-2.png"
                        alt=""
                      />
                    </figure>
                    <h3>Vinay</h3>
                    <span class="designation">React Developer</span>
                    <ul class="support-info">
                      <li>
                        Call: <a href="tel:8004561234">(800) 9456-1234</a>
                      </li>
                      <li>
                        <a href="mailto:baldwin@example.com">
                          vinaykumar@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sidebar-widget support-widget">
                  <div class="widget-content centred m-4">
                    <div class="pattern-layer">
                      <div
                        class="pattern-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-44.png)",
                        }}
                      ></div>
                      <div
                        class="pattern-2"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-45.png)",
                        }}
                      ></div>
                    </div>
                    <figure class="image-box">
                      <img
                        src="assets/images/resource/author-2.png"
                        alt=""
                      />
                    </figure>
                    <h3>Rajesh</h3>
                    <span class="designation">React Developer</span>
                    <ul class="support-info">
                      <li>
                        Call: <a href="tel:8004561234">(800) 9456-1234</a>
                      </li>
                      <li>
                        <a href="mailto:baldwin@example.com">
                          rajeshkumar@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sidebar-widget support-widget">
                  <div class="widget-content centred m-4">
                    <div class="pattern-layer">
                      <div
                        class="pattern-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-44.png)",
                        }}
                      ></div>
                      <div
                        class="pattern-2"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-45.png)",
                        }}
                      ></div>
                    </div>
                    <figure class="image-box">
                      <img
                        src="assets/images/resource/author-2.png"
                        alt=""
                      />
                    </figure>
                    <h3>Ravi Teja</h3>
                    <span class="designation">React Developer</span>
                    <ul class="support-info">
                      <li>
                        Call: <a href="tel:8004561234">(800) 9456-1234</a>
                      </li>
                      <li>
                        <a href="mailto:baldwin@example.com">
                          raviteja001@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sidebar-widget support-widget">
                  <div class="widget-content centred m-4">
                    <div class="pattern-layer">
                      <div
                        class="pattern-1"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-44.png)",
                        }}
                      ></div>
                      <div
                        class="pattern-2"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-45.png)",
                        }}
                      ></div>
                    </div>
                    <figure class="image-box">
                      <img
                        src="assets/images/resource/author-2.png"
                        alt=""
                      />
                    </figure>
                    <h3>Adhitya</h3>
                    <span class="designation">React Developer</span>
                    <ul class="support-info">
                      <li>
                        Call: <a href="tel:8004561234">(800) 9456-1234</a>
                      </li>
                      <li>
                        <a href="mailto:baldwin@example.com">
                          adhitya021@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </section>
      {/* <!-- React Course-details end --> */}
      <Footer />
    </div>
  );
};

export default VLSIdesignverification;
