import React from 'react';
import Slider from 'react-slick';

// Import CSS for react-slick carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonial = () => {
  // Slick settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true, // Change to false if you don't want arrows
  };

  return (
    <div>
      <section className="testimonial-section bg-color-2">
        <div className="auto-container">
          <div className="sec-title">
            <span className="top-title">Client Testimonials</span>
            <h2>Highest Client Satisfaction Rate</h2>
          </div>
          <Slider {...settings}>
            <div className="testimonial-block-one">
              <div className="inner-box">
                <div className="border-shap" style={{backgroundImage: "url(assets/images/shape/border-1.png)"}}></div>
                <figure className="quote-box">
                  <img src="assets/images/icons/quote-1.png" alt="" />
                </figure>
                <div className="author-box">
                  <figure className="author-thumb">
                    <img src="assets/images/resource/testimonial-1.png" alt="" />
                  </figure>
                  <ul className="rating clearfix">
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                  </ul>
                  <h3>Evan Clement - <span>Manager, Cypertech</span></h3>
                </div>
                <div className="text">
                  <p>
                    Ideants Technologies is very accurate when it comes to helping
                    you find a job and if that job finishes, they help you to
                    find another job placement!
                  </p>
                </div>
              </div>
            </div>
            <div className="testimonial-block-one">
              <div className="inner-box">
                <div className="border-shap" style={{backgroundImage: "url(assets/images/shape/border-1.png)"}}></div>
                <figure className="quote-box">
                  <img src="assets/images/icons/quote-1.png" alt="" />
                </figure>
                <div className="author-box">
                  <figure className="author-thumb">
                    <img src="assets/images/resource/testimonial-2.png" alt="" />
                  </figure>
                  <ul className="rating clearfix">
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                    <li><i className="flaticon-star"></i></li>
                  </ul>
                  <h3>Freddie Esther - <span>VP, Daily News</span></h3>
                </div>
                <div className="text">
                  <p>
                    I really appreciated the outstanding time, work, and effort
                    that the entire staff put into finding me an excellent job
                    placement. Thank you very much.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
