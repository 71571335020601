import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const Directhire = () => {
  return (
    <div>
      <Header />
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-120px" }}>
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white",fontWeight:"300" }}>WE KEEP IT SIMPLE</h2><h3 style={{color:"white"}}>Implemented Direct Hire staffing strategy</h3>
                          <p style={{ color: "black" }}>
                          We take the hassle out of hiring by carefully screening and handpicking the best candidates for your company. Our expert specializes in matching 
                          top candidates with the perfect job fit. Say goodbye to the time-consuming and costly process of finding the right employee and hello to an efficient and seamless hiring experience.
                          </p>{" "}
                          <br /> <br /> 
                          <div className="row ml-1 pb-2">
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">Get Hired</a>
                              </div>
                            </Link>
                            <Link
                              to=""
                              className="class_link"
                            >
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/banner/direct-hire.jpg"></img>
                      {/* <form
                        // method="post"
                        // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                        id="contact-form"
                        class="default-form form-group"
                      >
                        <div class=" clearfix">
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="username"
                              placeholder="Your Name *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="email"
                              // name="email"
                              placeholder="Email Address *"
                              required=""
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="phone"
                              required=""
                              placeholder="Phone *"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                            <input
                              type="text"
                              // name="subject"
                              required=""
                              placeholder="Subject"
                            />
                          </div>
                          <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                            <button
                              class="theme-btn-one"
                              type="submit"
                              // name="submit-form"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
            
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      {/* <!-- service-details --> */}
      <section class="service-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="service-details-content">
                <div class="content-one">
                  <div class="sec-title">
                    <h2>Direct Hire Staffing Services</h2>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/banner/Direct-Hire-Staffing.jpg" alt="" />
                  </figure>
                  <div class="text">
                    <p>
                    Direct hire staffing services can be particularly useful for 
                    companies that are struggling to find qualified candidates through 
                    traditional recruitment methods, or that have high-level positions that 
                    are difficult to fill. Using a staffing agency to find and recruit candidates 
                    can save a company time and resources, and can also provide access to a wider pool of qualified candidates.
                    </p>
                    
                  </div>
                </div>
                
                
                <div class="content-four">
                  <div class="upper-box">
                    <h3>Reasion to Choose Ideants for Direct Hire</h3>
                    <p>
                    As with anything new in your organization, you should weigh the pros and cons to choose the best fit for your company. Let’s review these below:
                    </p>
                  </div>
                  <div class="inner-box">
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-short-tie"></i>
                            </div>
                            <h5>Wider pool of talent</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Ideants can help your company access a wider pool of candidates, 
                                including both active and passive job seekers, increasing the chances of 
                                finding the right fit for the role.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-money-bag"></i>
                            </div>
                            <h5>Recruitment Expert</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                We have expertise in identifying and selecting top candidates, 
                                and can use our resources and knowledge to find the best fit for the company's needs.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Time and cost savings</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                With Ideants direct hire staffing, you can save time and resources, 
                                as we handles the entire recruitment process, including screening candidates, and coordinating interviews.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 column">
                        <ul class="list-item clearfix">
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-hourglass"></i>
                            </div>
                            <h5>Flexibility</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Ideants can provide a range of direct hire staffing options, 
                                including temporary-to-permanent and permanent placements, allowing your company to choose the best fit for their needs.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-lightbulb"></i>
                            </div>
                            <h5>Onboarding support</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Ideants can provide support with onboarding and integrating new hires into the company, helping to ensure a smooth transition.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="icon-box">
                              <i class="flaticon-google-maps"></i>
                            </div>
                            <h5>Risk management</h5>
                            <div class="more-content">
                              <div class="menu-icon">
                                <i class="flaticon-menu"></i>
                                <i class="flaticon-menu"></i>
                              </div>
                              <div class="text">
                                <p>
                                Ideants can help a company mitigate the risk of hiring the wrong person by thoroughly screening and evaluating candidates, and providing guarantees or replacements if necessary.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="service-sidebar">
                <div class="sidebar-widget categories-widget">
                  <ul class="categories-list clearfix">
                    <li>
                      <a href="temporary-staffing">Temprory Staffing</a>
                    </li>
                    <li>
                      <a href="direct-hire" class="active">
                        Direct Hire
                      </a>
                    </li>
                    <li>
                      <a href="contract-hire">Contract to Hire</a>
                    </li>
                    <li>
                      <a href="payrol-hire">Payrolling</a>
                    </li>
                    <li>
                      <a href="carporate-training">Corporate Training</a>
                    </li>
                    <li>
                      <a href="executive-search">Executive Search</a>
                    </li>
                  </ul>
                </div>
                <div class="sidebar-widget recruitment-widget">
                  <div class="widget-content">
                    <div class="text">
                      <div
                        class="pattern-layer"
                        style={{
                          backgroundImage:
                            "url(assets/images/shape/pattern-43.png)",
                        }}
                      ></div>
                      <figure class="image-box">
                        <img src="assets/images/resource/men-1.png" alt="" />
                      </figure>
                      <h3>Recruitment Solutions for All Industries...</h3>
                    </div>
                    <div class="link">
                      <a href="">
                        <i class="flaticon-right-arrow"></i>Place a Job Order
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sidebar-widget subscribe-widget">
                  <h3>Reach Out</h3>
                  <form
                    // action=""
                    // method="post"
                    class="subscribe-form"
                  >
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        // name="name"
                        placeholder="Mr/Mrs.xxxxx"
                        required=""
                      />
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        // name="name"
                        placeholder="example@domain.com"
                        required=""
                      />
                    </div>
                    {/* <div class="form-group">
                      <label>Interested In</label>
                      <div class="select-box">
                        <select class="wide">
                          <option data-display="Hiring Employees">
                            Hiring Employees
                          </option>
                          <option value="1">ATX Group</option>
                          <option value="2">Ajax Company</option>
                          <option value="3">Jhon Group</option>
                          <option value="4">Nike Japan</option>
                        </select>
                      </div>
                    </div> */}
                    <div class="form-group message-btn">
                      <button type="submit" class="theme-btn-one">
                        Send Your Interest
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- service-details end --> */}
      <Footer />
    </div>
  );
};

export default Directhire;
