import React from "react";

const Recruitment = () => {
  return (
    <div>
      <section className="recruitment-technology">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className="image-box js-tilt clearfix">
                <img src="assets/images/resource/recruitment-1.png" alt="" />
              </figure>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div id="content_block_4">
                <div className="content-box">
                  <div className="sec-title">
                    <span className="top-title">Recruitment technologies</span>
                    <h2>Solving Recruitment Using Technology</h2>
                    <p>
                      Revolutionizing recruitment through innovative technology
                      solutions, Ideants Technologies streamlines the hiring
                      process for unparalleled efficiency. Experience the future
                      of talent acquisition with our cutting-edge platform,
                      simplifying recruitment challenges with precision and
                      ease.
                    </p>
                  </div>
                  <div className="inner-box">
                    <div
                      className="single-item wow fadeInRight animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-7.png" alt="" />
                        </figure>
                        <h3>
                          <span>01</span>
                          <a href="#">
                            Sourcing the Best
                            <i className="flaticon-right-arrow"></i>
                          </a>
                        </h3>
                        <p>
                          Ideants Technologies: Your source for sourcing the
                          best talent effortlessly.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-item wow fadeInRight animated animated"
                      data-wow-delay="300ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-8.png" alt="" />
                        </figure>
                        <h3>
                          <span>02</span>
                          <a href="#">
                            Volume Hiring
                            <i className="flaticon-right-arrow"></i>
                          </a>
                        </h3>
                        <p>
                          Streamline high-volume hiring effortlessly with
                          Ideants Technologies.
                        </p>
                      </div>
                    </div>
                    <div
                      className="single-item wow fadeInRight animated animated"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="inner">
                        <figure className="icon-box">
                          <img src="assets/images/icons/icon-9.png" alt="" />
                        </figure>
                        <h3>
                          <span>03</span>
                          <a href="#">
                            Partners in Team Building
                            <i className="flaticon-right-arrow"></i>
                          </a>
                        </h3>
                        <p>
                          Ideants Technologies: Your partners in seamless team
                          building.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Recruitment;
