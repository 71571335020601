import React, { useState, useEffect } from "react";
import Rootstock from "./Components/Navigation/Rootstock";
function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Adjust offset as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollTopbtn = document.querySelector(".scroll-top");
    const progressPath = document.querySelector(".scroll-top path");

    if (scrollTopbtn && progressPath) {
      const pathLength = progressPath.getTotalLength();
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "none";
      progressPath.style.strokeDasharray = pathLength + " " + pathLength;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "stroke-dashoffset 10ms linear";

      const updateProgress = () => {
        const scroll = window.scrollY;
        const height =
          document.documentElement.scrollHeight - window.innerHeight;
        const progress = pathLength - (scroll * pathLength) / height;
        progressPath.style.strokeDashoffset = progress;
      };

      const handleScroll = () => {
        updateProgress();
        setIsScrolled(window.scrollY > 50); // Adjust offset as needed
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className={`scroll-top ${isScrolled ? "show" : ""}`}
        onClick={scrollTop}
        style={{
          zIndex: 9999
        }}
      >
        <i
          className="flaticon-up-arrow-1"
          style={{
            position: "relative",
            display: "block",
            fontSize: "30px",
            fontWeight: 400,
            marginTop: "30px",
          }}
        ></i>
        Top
      </div>
      <Rootstock />
    </>
  );
}

export default App;