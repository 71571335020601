import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Service = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div>
      <section className="service-section">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/pattern-6.png)" }}
        ></div>
        <div className="anim-icon">
          <div
            className="icon-1"
            style={{
              backgroundImage: "url(assets/images/icons/anim-icon-1.png)",
            }}
          ></div>
          <div className="icon-2"></div>
        </div>
        <div className="auto-container">
          <div className="sec-title centred">
            <span className="top-title">Services We Provide</span>
            <h2>Inspiring Staffing Solutions</h2>
            <p>
              Unlocking potential through inspired staffing solutions at Ideants
              Technologies, where innovation meets talent seamlessly.
            </p>
          </div>
          <Slider
            {...settings}
            className="three-item-carousel owl-carousel owl-theme owl-nav-none owl-dot-style-one"
          >
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-1.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-4.png" alt="" />
                      </figure>
                      <h4>Temporary</h4>
                    </div>
                    <div className="link">
                      <a href="temporary-staffing">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                      Elevate your workforce flexibility with Ideants
                      Technologies' seamless temporary hiring.
                    </p>
                    <a href="temporary-staffing">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-2.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-5.png" alt="" />
                      </figure>
                      <h4>Direct Hire</h4>
                    </div>
                    <div className="link">
                      <a href="direct-hire">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                      Discover direct hire excellence and connecting businesses
                      with top-tier talent passionately.
                    </p>
                    <a href="direct-hire">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-3.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-6.png" alt="" />
                      </figure>
                      <h4>Contract</h4>
                    </div>
                    <div className="link">
                      <a href="contract-hire">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                      Ensures seamless transitions from contract to hire,
                      matching talent with long-term opportunities.
                    </p>
                    <a href="contract-hire">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-3.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-61.png" alt="" />
                      </figure>
                      <h4>Payrolling</h4>
                    </div>
                    <div className="link">
                      <a href="payrol-hire">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                    Providing flexible staffing solutions to meet short-term needs, ensuring businesses.
                    </p>
                    <a href="payrol-hire">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-3.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-63.png" alt="" />
                      </figure>
                      <h4>Executive Search</h4>
                    </div>
                    <div className="link">
                      <a href="executive-search">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                      Ensures seamless transitions from contract to hire,
                      matching talent with long-term opportunities.
                    </p>
                    <a href="executive-search">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-block-one">
              <div className="inner-box">
                <figure className="image-box">
                  <img src="assets/images/service/service-3.jpg" alt="" />
                </figure>
                <div className="lower-content">
                  <div className="content-box">
                    <div className="inner">
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-62.png" alt="" />
                      </figure>
                      <h4>Training</h4>
                    </div>
                    <div className="link">
                      <a href="carporate-training">More Details</a>
                    </div>
                  </div>
                  <div className="overlay-content">
                    <p>
                      Ensures seamless transitions from contract to hire,
                      matching talent with long-term opportunities.
                    </p>
                    <a href="carporate-training">
                      <i className="flaticon-right-arrow"></i>More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Service;
