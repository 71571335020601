import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="main-footer bg-color-1">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/pattern-11.png)" }}
        ></div> 
        <div className="auto-container">
          <div className="footer-top">
            <div className="widget-section">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                  <div className="footer-widget about-widget">
                    <div className="widget-title">
                      <h3>Office Address</h3>
                    </div>
                    <div className="text">
                      <p>
                        It is a long established fact that a reader will
                        distracted by the readable ...
                        <i className="fas fa-arrow-up"></i>
                      </p>
                      <p>
                        2 nd Floor, <br />
                        Leelavathi Achar Complex, <br />
                        opp. Muthoot Gold, <br />
                        Immadihalli, Whitefield, <br />
                        Bengaluru, 560066, <br />
                        info@Ideants.com <br />
                        Ph:9032160768
                      </p>
                    </div>
                    <ul className="social-links clearfix">
                      <li>
                        <h5>Connected:</h5>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                  <div className="footer-widget links-widget">
                    <div className="widget-title">
                      <h3>Useful Links</h3>
                    </div>
                    <ul className="links-list clearfix">
                      <li>
                        <a href="#">About Us</a>
                      </li>
                      <li>
                        <a href="#">Resources</a>
                      </li>
                      <li>
                        <a href="#">Our Solutions</a>
                      </li>
                      <li>
                        <a href="#">Testimonials</a>
                      </li>
                      <li>
                        <a href="#">Employers</a>
                      </li>
                      <li>
                        <a href="#">How It’s Work</a>
                      </li>
                      <li>
                        <a href="#">Job Seekers</a>
                      </li>
                      <li>
                        <a href="#">Industries</a>
                      </li>
                      <li>
                        <a href="#">Leadership</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                  <div className="footer-widget contact-widget">
                    <div className="single-info-box">
                      <div className="widget-title">
                        <h3>For Employers</h3>
                      </div>
                      <ul className="info-box clearfix">
                        <li>
                          <a href="tel:9032160768">(+91) 90321-60768</a>
                        </li>
                        <li>
                          <a href="mailto:info@Ideants.com">info@Ideants.com</a>
                        </li>
                      </ul>
                    </div>
                    <div className="single-info-box">
                      <div className="widget-title">
                        <h3>For Employees</h3>
                      </div>
                      <ul className="info-box clearfix">
                        <li>
                          <a href="tel:9032160768">(+91) 90321-60768</a>
                        </li>
                        <li>
                          <a href="mailto:info@Ideants.com">info@Ideants.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-subscribe">
            <div className="text centred">
              <h3>Subscribe Us & Get Our Recruitment Updates in Your Inbox</h3>
            </div>
            <form action="#" method="post" className="subscribe-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address here ..."
                  required=""
                />
                <button type="submit">
                  <i className="flaticon-right-arrow"></i>Subscribe US
                </button>
              </div>
            </form>
          </div>
          <div className="footer-bottom clearfix">
            <div className="copyright pull-left">
              <p>
                &copy; 2024 <a href="#"></a>, All Rights Reserved @Ideants
                technologies
              </p>
            </div>
            <ul className="footer-nav pull-right clearfix">
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms & Conditions</a>
              </li>
              <li>
                <a href="#">Site Map</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
