import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faBuilding, faUser, faBook } from "@fortawesome/free-solid-svg-icons"; // Import the required icons

const Sidebar = () => {
  return (
    <div className="col-2">
      <div className="container-fluid">
        <div
          className="p-3 "
          style={{ height: "70vh", border: "1px solid lightgray" }}
        >
          <a href="/home" className="d-block mb-4">
            <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> Dashboard
          </a>
          <a href="/home-employers" className="d-block mb-4">
            <FontAwesomeIcon icon={faBuilding} className="mr-2" /> Employers
          </a>
          <a href="/home-job-seekers" className="d-block mb-4">
            <FontAwesomeIcon icon={faUser} className="mr-2" /> Job Seekers
          </a>
          <a href="/home-courses" className="d-block">
            <FontAwesomeIcon icon={faBook} className="mr-2" /> Courses
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
