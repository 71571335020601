import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";
import Clients from "../../Clients/Clients";

const ProfessionalCertification = () => {
  return (
    <div>
      <Header />
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={
          {
            // backgroundImage: "url(assets/images/background/page-title-2.jpg)",
          }
        }
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          {/* <!--Page Title--> */}
          <section
            class="page-title"
            style={{
              backgroundImage: "url(assets/images/background/page-title.jpg)",
            }}
          >
            <div
              class="pattern-layer"
              style={{
                backgroundImage: "url(assets/images/shape/pattern-35.png)",
              }}
            ></div>
            <div class="auto-container">
              <div class="content-box">
                {/* <!-- contactinfo-section --> */}
                <section class="contact-page-section">
                  <div class="auto-container">
                    <div class="row clearfix " style={{ marginTop: "-300px" }}>
                      <h2
                        style={{
                          color: "white",
                          fontWeight: "300",
                          textAlign: "center",
                        }}
                      >
                        Designed to transform you into a highly skilled Software
                        Professional
                      </h2>
                      <p style={{ color: "white" }}>
                        Work on real-world projects and gain industry-required
                        skills
                      </p>
                      <div
                        class="col-lg-4 col-md-12 col-sm-12 content-column "
                        style={{ marginTop: "30px" }}
                      >
                        <div id="content_block_6">
                          <div class="content-box">
                            <div class="sec-title">
                              {/* <p style={{ color: "black" }}>
                          We connect talent with fitting roles and assist businesses in achieving their goals.
                           Our approach involves employing management tools and principles, alongside policies 
                           fostering accountability within our sustainability framework: People, Planet, Profit.
                          </p>{" "} */}
                              <br />
                              {/* <div className="row ml-1 pb-2">
                          <Link to="" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12 col-sm-12 form-column">
                        <div
                          class="form-inner"
                          style={{
                            borderRadius: "12px",
                            boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                            padding: "20px",
                          }}
                        >
                          <img
                            src="assets/images/about/short-term-img.png"
                            alt="Image"
                            style={{
                              position: "absolute",
                              top: -50,
                              right: 40,
                              width: "100px", // Adjust width as needed
                              height: "auto", // Maintain aspect ratio
                              zIndex: 1, // Ensure the image appears above other content
                            }}
                          />
                          <h5>Short Term</h5>
                          <h2>Internship</h2>
                          <h6
                            className="pl-1 mt-5"
                            style={{
                              fontWeight: "",
                              backgroundColor: "#abc",
                              borderRadius: "5px",
                            }}
                          >
                            All final year, graduates, Post graduates
                          </h6>
                          <br />
                          <h5 style={{ fontWeight: "bold" }}>
                            Learn like top IITians and achieve high-paid
                            software jobs
                          </h5>{" "}
                          <br />
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="m-3">
                              Duration:
                              <br />3 months
                            </p>
                            <p className="m-3">
                              Next Batch:<br></br>22/04/2024
                            </p>
                          </div>
                          <div className="row ml-1 pb-2">
                            <Link
                              to="/short-term-Internship-training"
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Book a free Demo</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12 col-sm-12 form-column">
                        <div
                          class="form-inner"
                          style={{
                            borderRadius: "12px",
                            boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                            padding: "20px",
                          }}
                        >
                          <img
                            src="assets/images/about/long-term-img.png"
                            alt="Image"
                            style={{
                              position: "absolute",
                              top: -50,
                              right: 40,
                              width: "100px", // Adjust width as needed
                              height: "auto", // Maintain aspect ratio
                              zIndex: 1, // Ensure the image appears above other content
                            }}
                          />
                          <h5>Long Term</h5>
                          <h2>Internship</h2>
                          <h6
                            className="pl-1 mt-5"
                            style={{
                              fontWeight: "",
                              backgroundColor: "#abc",
                              borderRadius: "5px",
                            }}
                          >
                            BE, B.Tech, Any Degree 2021, 22, 23, 24
                          </h6>
                          <br />
                          <h5 style={{ fontWeight: "bold" }}>
                            A Proven Program to make you a Software Developer
                          </h5>{" "}
                          <br />
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="m-3">
                              Duration:
                              <br />6 months
                            </p>
                            <p className="m-3">
                              Next Batch:<br></br>22/04/2024
                            </p>
                          </div>
                          <div className="row ml-1 pb-2">
                            <Link
                              to="/long-term-Internship-training"
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Book a free Demo</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12 col-sm-12 form-column">
                        <div
                          class="form-inner"
                          style={{
                            borderRadius: "12px",
                            boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                            padding: "20px",
                          }}
                        >
                          <img
                            src="assets/images/got-job.png"
                            alt="Image"
                            style={{
                              position: "absolute",
                              top: -50,
                              right: 40,
                              width: "100px", // Adjust width as needed
                              height: "auto", // Maintain aspect ratio
                              zIndex: 1, // Ensure the image appears above other content
                            }}
                          />
                          <h5>Placement</h5>
                          <h2>
                            Guarantee <bR></bR>Program
                          </h2>
                          <h5 style={{ fontWeight: "bold" }}>
                            Full Stack Development
                          </h5>{" "}
                          <ul>
                            <li>
                              {" "}
                              <i class="flaticon-right-arrow"></i> 100%
                              placement guarantee
                            </li>
                            <li>
                              {" "}
                              <i class="flaticon-right-arrow"></i> Direct access
                              to our 5000 + partner companies
                            </li>
                            <li>
                              {" "}
                              <i class="flaticon-right-arrow"></i> 200hrs of
                              learning | Completely ⬤ LIVE
                            </li>
                            <li>
                              {" "}
                              <i class="flaticon-right-arrow"></i> 1 : 1 doubt
                              resolution
                            </li>
                            <li>
                              {" "}
                              <i class="flaticon-right-arrow"></i> Flexible
                              curriculum + Lifetime access
                            </li>
                          </ul>
                          <div className="row ml-1 pb-2">
                            <Link
                              to="/placement-guarantee-program"
                              className="class_link"
                            >
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Book a free Demo</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          {/* <!--End Page Title--> */}
        </div>
      </section>
      <section class="welcome-section">
            <div class="auto-container">
                <div class="sec-title centred" >
                    <h2 style={{fontWeight:"300"}}>Loved by students & companies around the world</h2>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-12 col-sm-12 image-column">
                        <figure class="image-box js-tilt"><img src="assets/images/professional.png" alt=""></img></figure>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                        <div id="content_block_1">
                            <div class="content-box">
                                <div class="tabs-box">
                                    <div class="tab-btn-box">
                                        {/* <ul class="tab-btns tab-buttons clearfix">
                                            <li class="tab-btn active-btn" data-tab="#tab-1">
                                                <i class="employ-icon flaticon-businessman"></i>
                                                <h5>For Employers</h5>
                                                <i class="arrow-icon flaticon-up-arrow-2"></i>
                                            </li>
                                            <li class="tab-btn" data-tab="#tab-2">
                                                <i class="employ-icon flaticon-employer"></i>
                                                <h5>For Employees</h5>
                                                <i class="arrow-icon flaticon-up-arrow-2"></i>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div class="tabs-content">
                                        <div class="tab active-tab" id="tab-1">
                                            <div class="inner-box">
                                                <h2 style={{fontWeight:"300"}}>Professional Experience Anytime, Anywhere.</h2>
                                                <p>Build your resume with an Ideants - real world experience with integrated learning.</p>
                                                <p>About two-thirds of “entry-level” jobs require at least three years of work experience — but 
                                                  coveted internship opportunities can be hard to get.</p>
                                                  <p>Learn how the Ideants is making professional experience more accessible to learners everywhere.</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <section class="awards-section" style={{marginTop:"-150px"}}>
        <div class="auto-container">
          <div class="sec-title centred">
            <h2>50+ Companies have hired Ideants learners</h2>
            <p>
              Ideants students carved a name for themselves in the IT industry.
              Hear it directly from the CEOs, CXOs and HRs of tech companies..
            </p>
          </div>
          <div class="row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12 inner-column">
              <div class="inner-block">
                <div class="single-award-block">
                  <div class="inner-box">
                    <div class="upper-box">
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-19.png" alt=""></img>
                      </figure>
                      <h3>Get Upskilled</h3>
                    </div>
                    <p>
                      The #1 way college students & early graduates get
                      Internships, Jobs
                    </p>
                  </div>
                </div>
                <div class="single-award-block">
                  <div class="inner-box">
                    <div class="upper-box">
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-19.png" alt=""></img>
                      </figure>
                      <h3>Create Profile</h3>
                    </div>
                    <p>
                      Create your profile with easy-to fill form and get
                      recommended with relevant internships/ jobs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 image-column">
              <figure class="image-box js-tilt">
                <img src="assets/images/logo-3.png" alt=""></img>
              </figure>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 inner-column">
              <div class="inner-block">
                <div class="single-award-block">
                  <div class="inner-box">
                    <div class="upper-box">
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-19.png" alt=""></img>
                      </figure>
                      <h3>Hassle Free</h3>
                    </div>
                    <p>
                      An effortless way to get internships/ jobs through a quick
                      and easy application process.
                    </p>
                  </div>
                </div>
                <div class="single-award-block">
                  <div class="inner-box">
                    <div class="upper-box">
                      <figure class="icon-box">
                        <img src="assets/images/icons/icon-19.png" alt=""></img>
                      </figure>
                      <h3>Get Deploy to Client with upto 24LPA Job</h3>
                    </div>
                    <p>
                      Our top notch teams involved in helping you learn
                      programming, not just coding.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Clients />

      <section class="awards-section">
        <div class="auto-container">
          <div class="sec-title centred">
            <h2>500+ student’s life impacted and continuing</h2>
            <p>
              Learn from our top instructors Get a Guaranteed Job upto 25 LPA
            </p>
          </div>
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-1.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Naveen Kumar
                  </h3>
                  <p class="card-text">MERN Full Stack Developer</p>
                  {/* <h5 class="card-title">TCS</h5> */}
                  <p>Package: 6.8 LPA</p>

                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img src="assets/images/clients/tcs.png" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-2.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Vinay{" "}
                  </h3>
                  <p class="card-text">Java Developer</p>
                  {/* <h5 class="card-title">Microsoft</h5> */}
                  <p>Package: 10.5 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/microsoft.png"
                    alt=""
                    style={{ marginTop: "40px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-3.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h3 class="card-title" style={{ fontWeight: "bold" }}>
                    Ramya
                  </h3>
                  <p class="card-text">Softeware Engineer</p>
                  {/* <h5 class="card-title">Infosys</h5> */}
                  <p>Package: 10 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/infosys.png"
                    alt=""
                    style={{ width: "120px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 inner-column">
              <div class="card" style={{ width: "18rem", textAlign: "center" }}>
                <img
                  class="card-img-top"
                  src="assets/images/team/team-4.jpg"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <h4 class="card-title" style={{ fontWeight: "bold" }}>
                    Rakesh
                  </h4>
                  <p class="card-text">Web Developer</p>
                  {/* <h5 class="card-title">Google</h5> */}
                  <p>Package: 8.3 LPA</p>
                  {/* <a href="#" class="btn btn-primary">
                   View More
                  </a> */}
                  <img
                    src="assets/images/clients/google.png"
                    alt=""
                    style={{ width: "150px", marginTop: "38px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}
      <Footer />
    </div>
  );
};

export default ProfessionalCertification;
