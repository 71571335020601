import React from "react";
import "./Slider.css";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <div id="demo" className="carousel slide" data-ride="carousel">
      <ul className="carousel-indicators">
        <li data-target="#demo" data-slide-to="0" className="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
        <li data-target="#demo" data-slide-to="2"></li>
      </ul>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-6.jpg"
            alt="Los Angeles"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h3
                          style={{
                            color: "white",
                            paddingTop: "100px",
                            fontSize: "36px",
                          }}
                        >
                          PRACTICAL, RESULT-ORIENTED, UPDATED AND CUSTOMISED
                          TRAINING PROGRAMS
                        </h3>
                        <p style={{ color: "black" }}>
                          Our core values are quality, honesty & integrity which
                          is demonstrated through every session conducted.
                        </p>{" "}
                        <br /> <br /> <br />
                        <div className="row ml-1 pb-2">
                          <Link to="job-seekers" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                          <Link to="employers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Request For Tallent</a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/banner-item-1.png"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      PROFESSIONAL CERTIFICATION TRAINING PROGRAMS
                    </h5>
                    <br></br>
                    <Link to="/professional-certification-training">
                    <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                      <button
                        class="theme-btn-one"
                        type="submit"
                        // name="submit-form"
                      >
                        More Details
                      </button>
                    </div>
                    </Link>
                    {/* <form
                      // method="post"
                      action="/professional-certification-training"
                      id="contact-form"
                      class="default-form form-group"
                    >
                      <div class=" clearfix">
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="email"
                            // name="email"
                            placeholder="Email Address *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="subject"
                            required=""
                            placeholder="Subject"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- contactinfo-section end --> */}
        </div>
        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-4.jpg"
            alt="Chicago"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            paddingTop: "100px",
                            fontWeight: "300",
                          }}
                        >
                          We match exceptional talent with outstanding
                          companies.
                        </h2>
                        <p style={{ color: "black" }}>
                          To be the most recognized “Perfect”, “Reliable” &
                          “Professional” human resource partner across the
                          globe.
                        </p>{" "}
                        <br /> <br /> <br />
                        <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="job-seekers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Get Hired</a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/banner-item-2.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      BRIDGING THE GAP BETWEEN PEOPLE AND OPPORTUNITIES
                    </h5>
                    <br></br>
                    <Link to="employers">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Request For Tallent
                        </button>
                      </div>
                    </Link>
                    {/* <form
                      // method="post"
                      action=""
                      id="contact-form"
                      class="default-form form-group"
                    >
                      <div class=" clearfix">
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="email"
                            // name="email"
                            placeholder="Email Address *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="subject"
                            required=""
                            placeholder="Subject"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- contactinfo-section end --> */}
        </div>
        <div className="carousel-item">
          <div className="overlay"></div> {/* Overlay added here */}
          <img
            src="assets/images/banner/banner-5.jpg"
            alt="New York"
            width="1100"
            height="500"
            style={{ objectFit: "cover" }}
          />
          {/* <!-- contactinfo-section --> */}
          <section class="contact-page-section">
            <div class="auto-container">
              <div class="row clearfix mb-5" style={{ marginTop: "-600px" }}>
                <div
                  class="col-lg-8 col-md-12 col-sm-12 content-column "
                  style={{ marginTop: "30px" }}
                >
                  <div id="content_block_6">
                    <div class="content-box">
                      <div class="sec-title sec-title1">
                        <h2
                          style={{
                            color: "white",
                            paddingTop: "100px",
                            fontWeight: "300",
                          }}
                        >
                          Recruit. Engage. Retain.
                        </h2>
                        <h3 style={{ color: "white" }}>
                          Enterprise Employee Lifecycle Solutions
                        </h3>
                        <p style={{ color: "black" }}>
                          We focus on connecting with talent, because we
                          understand the importance of a job in a person's life.
                          Focusing on the relationship with our talent will also
                          contribute to our clients' success
                        </p>{" "}
                        <br /> <br /> <br />
                        <div className="row ml-1 pb-2">
                          <Link to="/our-courses" className="class_link">
                            <div className="viewmore p-4 m-1 ">
                              <a href="">View Courses</a>
                            </div>
                          </Link>
                          <Link to="employers" className="class_link">
                            <div className="booknow p-4 m-1 ">
                              <a href="">Request For Tallent</a>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 form-column form-column1">
                  <div
                    class="form-inner"
                    style={{
                      borderRadius: "12px",
                      marginTop: "120px",
                      padding: "2px",
                      backgroundColor: "04d3f480",
                    }}
                  >
                    <div>
                      <img
                        src="assets/images/banner/banner-item-3.jpg"
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "250px",
                          borderRadius: "12px 12px 0px 0px",
                        }}
                      ></img>
                    </div>
                    <h5
                      style={{
                        color: "#91298d",
                        paddingTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      STAFFING AND CONSULTING, <br></br> HR SERVICES
                    </h5>
                    <br></br>
                    <Link to="job-seekers">
                      <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                        <button
                          class="theme-btn-one"
                          type="submit"
                          // name="submit-form"
                        >
                          Get Hired
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- contactinfo-section end --> */}
        </div>
      </div>
      <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <span className="carousel-control-prev-icon"></span>
      </a>
      <a className="carousel-control-next" href="#demo" data-slide="next">
        <span className="carousel-control-next-icon"></span>
      </a>
    </div>
  );
};

export default Slider;
