import React from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Link } from "react-router-dom";

const VLSIembeddedsystems = () => {
  return (
    <div>
      <Header/>
      {/* <!--Page Title--> */}
      <section
        class="page-title"
        style={{
          backgroundImage: "url(assets/images/background/page-title-2.jpg)",
        }}
      >
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/shape/pattern-35.png)",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            {/* <!-- contactinfo-section --> */}
            <section class="contact-page-section">
              <div class="auto-container">
                <div class="row clearfix " style={{ marginTop: "-150px" }}>
                  <div
                    class="col-lg-7 col-md-12 col-sm-12 content-column "
                    style={{ marginTop: "30px" }}
                  >
                    <div id="content_block_6">
                      <div class="content-box">
                        <div class="sec-title">
                          <h2 style={{ color: "white", fontWeight: "300" }}>
                          VLSI Embedded Systems training with Certification
                          </h2>
                          <p style={{ color: "white", fontWeight: "200" }}>
                          A VLSI Embedded Systems training with certification program would equip participants with the knowledge and skills required to design, develop, and deploy embedded systems using VLSI technology.This comprehensive training program would prepare participants for a career in embedded systems design and development using VLSI technology, providing them with the necessary knowledge, skills, and certification for success in the field.
                          </p>{" "}
                          <br />
                          <div className="row ml-1 pb-2">
                            <Link to="" className="class_link">
                              <div className="viewmore p-4 m-1 ">
                                <a href="">View More</a>
                              </div>
                            </Link>
                            <Link to="" className="class_link">
                              <div className="booknow p-4 m-1 ">
                                <a href="">Talk to Our Advisor</a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 col-sm-12 form-column">
                    <div
                      class="form-inner"
                      style={{
                        borderRadius: "12px",
                        boxShadow: "0 0 30px rgba(255, 255, 255, 0.9)",
                        padding: "20px",
                      }}
                    >
                      <img src="assets/images/courses/VLSI-Embedded-Systems.jpg"></img>
                      {/* <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form form-group"
                    >
                      <div class=" clearfix">
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="email"
                            // name="email"
                            placeholder="Email Address *"
                            required=""
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group">
                          <input
                            type="text"
                            // name="subject"
                            required=""
                            placeholder="Subject"
                          />
                        </div>
                        <div class="col-lg-12 col-md-6 col-sm-6 form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- contactinfo-section end --> */}
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- React Course -details --> */}
      <section class="job-details">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="col-lg-8 col-md-12 col-sm-12 content-side">
              <div class="job-details-content">
                <div class="text">
                  <h2>Course Description</h2>
                  <p>
                  Introduction to C, Elements of C, Operators, Flow Control Statements, Functions, Arrays, Pointers, Macros, Dynamic Memory Allocation, Strings, Structures, Unions, File handling, Enumeration (or enum), Renaming Data types with typedef, Bit-Manipulation, Type Casting, Executable file format, command-line arguments, qualifiers, Compilation stages, Debugging Techniques, Data Structure Basics, Arrays, Single & double linked list, Stack and Queue, Sorting Techniques, Searching Techniques, construction of libraries.
                  </p>
                  <p>
                  Kernel Architecture, CPU Scheduling, Deadlock & starvation, Linux Interfaces, APIs and System Calls(Shell& Services, Utilities, Applications, Libraries), Memory Management, File Sub-System, Process Control, signals, threads, IPC Mechanisms (POSIX & SYSTEM-V), High Performance, Secure coding through OS features, Network, Internet protocols(TCP/UDP), Client-Server Programming, Multi-threaded Programming, Lab Practice, and assignments.
                  </p>
                  {/* <h3>Responsibilities</h3>
                <ul class="list clearfix">
                  <li>
                  Developing user interface components using React.js concepts and best practices.
                  </li>
                  <li>
                  Translating UI/UX design wireframes into high-quality code.
                  </li>
                  <li>
                  Optimizing components for maximum performance across various browsers and devices.
                  </li>
                  <li>
                  Collaborating with back-end developers to integrate front-end components with server-side logic.
                  </li>
                </ul>
                <h3>Skills</h3>
                <ul class="list clearfix">
                  <li>
                  Proficiency in HTML, CSS, and JavaScript.
                  </li>
                  <li>
                  Strong understanding of UI/UX design principles.
                  </li>
                  <li>
                  Experience with React.js and its ecosystem (React Router, Redux, etc.).
                  </li>
                  <li>
                  Ability to create responsive and mobile-friendly interfaces.
                  </li>
                  <li>
                  Familiarity with version control systems (e.g., Git).
                  </li>
                </ul> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div class="job-sidebar">
                <div
                  class=" form-column"
                  style={{
                    boxShadow: "0px 0px 1px 1px lightblue",
                    borderRadius: "10px",
                    borderTop: "10px solid #662d91",
                    paddingBottom: "3px",
                  }}
                >
                  <div class="form-inner" style={{ margin: "20px" }}>
                    <form
                      // method="post"
                      // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                      id="contact-form"
                      class="default-form"
                    >
                      <div class=" clearfix">
                        <h4 style={{ textAlign: "center" }}>
                          Free Career Counselling
                        </h4>
                        <p style={{ textAlign: "center" }}>
                          We are happy to help you 24/7
                        </p>

                        <div class=" form-group mt-3">
                          <input
                            type="text"
                            // name="username"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>

                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Phone *"
                          />
                        </div>
                        <div class=" form-group">
                          <input
                            type="text"
                            // name="phone"
                            required=""
                            placeholder="Email Id *"
                          />
                        </div>
                        {/* <div class=" form-group">
                    <input
                      type="text"
                      // name="location"
                      placeholder="Location "
                    />
                  </div>
                  <div class=" form-group">
                    <input
                      type="text"
                      // name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>
                  <div class=" form-group">
                    <textarea
                      // name="message"
                      placeholder="Message ..."
                    ></textarea>
                  </div> */}
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <div class="sidebar-widget support-widget mt-5">
                <div class="widget-content centred">
                  <div class="pattern-layer">
                    <div
                      class="pattern-1"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-44.png)",
                      }}
                    ></div>
                    <div
                      class="pattern-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/pattern-45.png)",
                      }}
                    ></div>
                  </div>
                  <figure class="image-box">
                    <img src="assets/images/resource/author-2.png" alt="" />
                  </figure>
                  <h3>Shahid</h3>
                  <span class="designation">Trainer</span>
                  <ul class="support-info">
                    <li>
                      Call: <a href="tel:8004561234">(800) 456-1234</a>
                    </li>
                    <li>
                      Mail:{" "}
                      <a href="mailto:baldwin@example.com">
                        shahid@gmail.com
                      </a>
                    </li>
                  </ul>
                  <ul class="social-links">
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="job-details.html">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 column">
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Why VLSI Embedded Systems Certification Training from Ideants
                </h2>
              </div>
            </div>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-130px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Live Interactive Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> World-Class
                            Instructors
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Expert-Led
                            Mentoring Sessions
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Instant doubt
                            clearing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Lifetime Access</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Access
                            Never Expires
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Free Access to
                            Future Updates
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Unlimited
                            Access to Course Content
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>24x7 Live & desk Support.</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> One-On-One
                            Learning Assistance
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Help Desk
                            Support
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Resolve Doubts
                            in Real-time
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                      <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                          <div class="inner-box">
                              <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                              <h3>Broad Network</h3>
                              <p>Business it will frequently pleasures repudiated.</p>
                              <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                          </div>
                      </div>
                  </div> */}
                </div>
              </div>
            </section>
            <section
              class="chooseus-section alternet-2 sec-pad-2"
              style={{ marginTop: "-240px" }}
            >
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></img></figure> */}
                        <h3>Hands-On Project Based Learning</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>{" "}
                            Industry-Relevant Projects
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Course Demo
                            Dataset & Files
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Quizzes &
                            Assignments
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="200ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></img></figure> */}
                        <h3>Industry Recognised Certification</h3>
                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i> Ideants
                            Training Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Graded
                            Performance Certificate
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Certificate of
                            Completion
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 chooseus-block">
                    <div
                      class="chooseus-block-one wow fadeInUp animated animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="inner-box">
                        {/* <figure class="icon-box"><img src="assets/images/icons/icon-46.png" alt=""></img></figure> */}
                        <h3>Opportunity in Top MNCs</h3>
                        <li>
                          <i class="flaticon-right-arrow"></i> 

Software Integrator - Embedded System

                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i> Embedded Software Development Engineer
                        </li>
                        <li>
                          <i class="flaticon-right-arrow"></i>Embedded C Developer - Linux/Microcontroller
                        </li>
                        <br></br>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 col-sm-12 chooseus-block">
                      <div class="chooseus-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                          <div class="inner-box">
                              <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></img></figure>
                              <h3>Broad Network</h3>
                              <p>Business it will frequently pleasures repudiated.</p>
                              <a href="index-2.html"><i class="flaticon-right-arrow"></i>More Details</a>
                          </div>
                      </div>
                  </div> */}
                </div>
              </div>
            </section>
            <div
              class="col-lg-12 col-md-12 col-sm-12 column"
              style={{ marginTop: "-50px" }}
            >
              <div class="related-job">
                <h2 style={{ textAlign: "center" }}>
                  Instructor-led VLSI Embedded Systems live online Training Schedule &
                  Flexible batches for you
                </h2>
              </div>
            </div>
            <section
              class="about-section sec-pad"
              style={{ marginTop: "-100px" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-4 col-md-12 col-sm-12 content-column"
                      style={{
                        lineHeight: "150%",
                        backgroundColor: "#fdf9f3",
                        borderRight: "3px solid #F8EEDF",
                      }}
                    >
                      <div
                        id="content_block_2"
                        style={{ padding: "30px", textAlign: "center" }}
                      >
                        {/* <h4>
                          Price: ₹
                          <span style={{ textDecoration: "line-through" }}>
                            29,999
                          </span>
                        </h4> */}
                        <h1>₹85,000/- [+ 18% GST].</h1>
                        {/* <p>17% OFF , Save ₹5000.</p> */}
                        <br></br>
                        {/* <h2>Duration: 45 Days</h2> */}
                        <h4>May 22 - Orientation Class</h4>
                        <br></br>
                        <div class=" form-group message-btn centred">
                          <button
                            class="theme-btn-one"
                            type="submit"
                            // name="submit-form"
                          >
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3 style={{ fontSize: "42px" }}>
                            VLSI Embedded Systems Certification Training Course
                            </h3>
                            <hr />
                          </div>
                          <div
                            class="container-fluid"
                            style={{ width: "100%" }}
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <ul>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Introduction to Embedded Systems


                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Digital Logic and VLSI Design Fundamentals

</li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Embedded Processors and Microcontrollers

</li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> Hardware Description Languages (HDLs) for Embedded Systems


                                  </li>
                                  <li style={{ display: "list-item" }}>
                                    <i class="flaticon-right-arrow"></i> System-on-Chip (SoC) Design


                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>Peripheral Interfaces and Protocols


                                  </li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Real-Time Operating Systems (RTOS)

</li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>{" "}
                                    Embedded Software Development

</li>
                                  <li>
                                    <i class="flaticon-right-arrow"></i>Embedded System Verification and Testing


                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="about-section sec-pad"
              style={{ marginTop: "-190px", width: "100%" }}
            >
              <div class="pattern-layer"></div>
              <div class="auto-container">
                <div class="inner-container">
                  <div class="row clearfix">
                    <div
                      class="col-lg-6 col-md-12 col-sm-12 content-column"
                      style={{ borderRight: "2px solid lightgrey" }}
                    >
                      <div id="content_block_2" style={{ padding: "30px" }}>
                        <h3 style={{ textAlign: "center", fontSize: "36px" }}>
                          Skills Covered
                        </h3>
                        <hr></hr>

                        <ul>
                          <li>
                            <i class="flaticon-right-arrow"></i>Digital Logic Design Skills
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> VLSI Design Fundamentals
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Embedded Processors and Microcontrollers Skills
                          </li>
                          <li>
                            {" "}
                            <i class="flaticon-right-arrow"></i> System-on-Chip (SoC) Design Skills
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i>Peripheral Interfaces and Protocols Skills
                          </li>
                          <li>
                            <i class="flaticon-right-arrow"></i> Real-Time Operating Systems (RTOS) Skills
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div id="content_block_3">
                        <div class="content-box">
                          <div class="sec-title">
                            <h3
                              style={{ textAlign: "center", fontSize: "36px" }}
                            >
                              Tools Covered
                            </h3>
                            <hr></hr>
                            <img src="assets/images/courses/angular.png"></img>
                            <img src="assets/images/courses/typescript.png"></img>
                            <img src="assets/images/courses/git.png"></img>
                            <img src="assets/images/courses/docker.png"></img>
                            <img src="assets/images/courses/jwt.png"></img>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Short term Internship Program Details
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class=" form-group message-btn centred">
                    <button
                      class="theme-btn-one"
                      type="submit"
                      // name="submit-form"
                    >
                      Long term Internship Program Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section
              class="faq-section text-left"
              style={{ width: "100%", marginTop: "-20px" }}
            >
              <div class="container-fluid text-left" style={{ width: "100%" }}>
                <div class="row">
                  <div class="col-md-8">
                    <section
                      class="btc-accordion text-left"
                      style={{ width: "100%" }}
                    >
                      <div
                        class="container text-left"
                        style={{ width: "100%" }}
                      >
                        <h1 class="text-center text-black">
                        VLSI Embedded Systems Certification Training Course Curriculum
                        </h1>
                        <br />
                        <p class="text-center text-black">
                          <h4>Curriculum Designed by Experts</h4>{" "}
                        </p>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="accordion mb-2" id="accordionExample">
                              <div class="card">
                                <div
                                  class="card-header collapsed"
                                  id="headingOne"
                                >
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      1. Embedded Programming Essentials (15 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C Language for Embedded Programming, C Input /Output Functions, C Data Types for Embedded Programming, C Language Operators
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C Control Statements, Function Prototypes, Introduction to Pointers, C Storage Classes and Type Qualifiers
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C Compilation Process, C Preprocessor Directives, Arrays in C, Arrays to Functions, and Array of Function Pointers                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Command Line Arguments, Variadic Functions, Strings and String Functions in C, Structures in C, Unions and Bitfields in C                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Advanced Function Arguments, Advanced Pointers-Void Pointers, File Operations in C, Standard Libraries C                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C Memory Management, Dynamic Memory Allocation, Modular C Programming, Modular C Programming, and C Program Optimization                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Stack and Stack Operations, Queue and Queue Operations, Linked List, Finite State Machine                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Process Management in C, Embedded Design Patterns                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            C and Embedded C Standards             
                                                                         </li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            AUTOSAR C Rules and MISRA C Guidelines

</li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to C++ and OOPS-Part                                                                          </li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Constructor and Destructors                                                                         </li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Keywords in C++                                                                         </li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Type Casting                                                                         </li>
                                                                         <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Exception Handling                                                                         </li>

                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Embedded programming involves writing software that directly interacts with hardware components within embedded systems, which are dedicated computing devices performing specific functions.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
Understanding Embedded System                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
Programming Languages                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <h5 class="py-2">
                                      {" "}
                                      2. Fundamentals of Embedded Systems(9
                                      Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="collapse"
                                  aria-labelledby="headingTwo"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Role of Embedded Systems in Mechatronic Systems                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            ARM (MCU) Architecture                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Sensors, Actuators, and Instrumentation Cluster                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Embedded Systems Development                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Software Development Life Cycle                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Safety Standards                                            
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arduino Digital Pins, GPIO Input/Output Modes, and Methods of Programming Digital GPIO                                     </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Analog Data, Analog to Digital Conversion, Digital to Analog Conversion, Analog Data, ADC, and DAC in Mbed                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Timers in AVR based Microcontrollers, Concepts of PWM, and PWM in Mbed
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
Embedded systems are specialized computing systems designed to perform dedicated functions, often with real-time constraints, and are integrated into larger devices.                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                             Key components include microcontrollers or microprocessors, memory, input/output interfaces, and software that controls the hardware, emphasizing efficiency, reliability, and real-time operation for applications such as automotive systems, medical devices, and consumer electronics.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
Design and Development Process                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
Safety and Reliability                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingThree">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <h5 class="py-2">
                                      3. AVR Bare Metal Programming- Live Sessions (8 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  aria-labelledby="headingThree"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Arduino Vs Bare Metal Programming and Compiling Program with Custom Make Files                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Pinout and Features of Atmega328 and AVR Architecture                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Setting up Microchip Studio for AVR using SimulIDE to Simulate the Target Environment and Input & Output Ports of Atmega328                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Debugging the Code in Microchip Studio and Basics of Timers, Counters, and PWM                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            LCD Interface and Basics of ADC                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Analog Comparator and Interrupt
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Communication Protocols and Non-Volatile Memories                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Saving Power and Code Protection                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              AVR bare metal programming involves writing low-level code directly on AVR microcontrollers without an operating system, focusing on direct hardware manipulation and control.
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                             Live sessions typically cover topics such as microcontroller architecture, setting up the development environment, configuring peripherals (like timers, GPIO, UART), and writing efficient code for real-time applications, providing hands-on experience and real-time feedback.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Introduction to AVR Microcontrollers
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Understanding AVR Architecture
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header" id="headingFour">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    <h5 class="py-2">
                                      4. Software Verification and Validation and System Testing for Hand Code(7 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Introduction to Verification and Validation and System Testing                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Test Case Development from Requirements – I                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>
                                            Test Case Development from Requirements – II                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Static Analysis and MISRA-C Guidelines                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Unit Testing and Integration Testing                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Automation and CI/CD
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Tool Introduction, Familiarization and Static Analysis
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Software verification and validation (V&V) ensure that hand-written code meets specified requirements and works as intended, encompassing activities like code reviews, static analysis, and dynamic testing.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Software Verification and Validation (V&V)                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                            System Testing, Steps in V&V and System Testing for Hand Code

                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card">
                                <div class="card-header" id="headingFive">
                                  <a
                                    class="accordion-icon"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    <h5 class="py-2">
                                      5. Device Drivers and Serial Communication Protocols (7 Topics){" "}
                                      <span class="float-right bgarrow">
                                        <i class="fas fa-angle-down"></i>
                                      </span>
                                    </h5>
                                  </a>
                                </div>
                                <div
                                  id="collapseFive"
                                  class="collapse"
                                  aria-labelledby="headingFour"
                                  data-parent="#accordionExample"
                                >
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h3>Topics</h3>
                                        <br />
                                        <ul>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Theoretical Introduction to ARM on-chip peripherals                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Bare metal programming with board bring up                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Driver development for GPIO, UART, SPI, I2C interfaces                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Hands-on Peripheral Interfacing using SPI/I2C Interfaces
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            Theoretical Introduction on CAN, Ethernet, ARINC
                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CAN Driver Development (Hands-on)                                          </li>
                                          <li>
                                            <i class="flaticon-right-arrow"></i>{" "}
                                            CAN data analysis and visualization (Hands-on)                                          </li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Hands-On
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              Device drivers are specialized software modules that allow the operating system and software applications to communicate with and control hardware devices, providing an interface between the hardware and higher-level software.
                                            </li>
                                          </ul>
                                        </div>

                                        <div
                                          class="card"
                                          style={{ display: "flex" }}
                                        >
                                          <div class="circle-container">
                                            <div class="circle">
                                              <div class="checkmark"></div>
                                            </div>
                                            <div style={{ marginLeft: "4px" }}>
                                              {" "}
                                              Skills you will learn
                                            </div>
                                          </div>
                                          <ul style={{ paddingLeft: "30px" }}>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Purpose of Device Drivers
                                            </li>
                                            <li
                                              style={{
                                                display: "list-item",
                                                listStyleType: "Square",
                                                listStyle: "Square",
                                              }}
                                            >
                                              {" "}
                                              Types of Device Drivers
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="col-md-4">
                    <div
                      class=" form-column"
                      style={{
                        boxShadow: "0px 0px 1px 1px lightblue",
                        borderRadius: "10px",
                        borderTop: "10px solid #662d91",
                        paddingBottom: "3px",
                        width: "100%",
                        marginTop: "155px",
                      }}
                    >
                      <div class="form-inner" style={{ margin: "20px" }}>
                        <form
                          // method="post"
                          // action="https://st.ourhtmldemo.com/new/Eazyrecruitz/sendemail.php"
                          id="contact-form"
                          class="default-form"
                        >
                          <div class=" clearfix">
                            <h4 style={{ textAlign: "center" }}>
                              Free Career Counselling
                            </h4>
                            <p style={{ textAlign: "center" }}>
                              We are happy to help you 24/7
                            </p>

                            <div class=" form-group mt-3">
                              <input
                                type="text"
                                // name="username"
                                placeholder="Your Name *"
                                required=""
                              />
                            </div>

                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Phone *"
                              />
                            </div>
                            <div class=" form-group">
                              <input
                                type="text"
                                // name="phone"
                                required=""
                                placeholder="Email Id *"
                              />
                            </div>
                            {/* <div class=" form-group">
                    <input
                      type="text"
                      // name="location"
                      placeholder="Location "
                    />
                  </div>
                  <div class=" form-group">
                    <input
                      type="text"
                      // name="subject"
                      required=""
                      placeholder="Subject"
                    />
                  </div>
                  <div class=" form-group">
                    <textarea
                      // name="message"
                      placeholder="Message ..."
                    ></textarea>
                  </div> */}
                            <div class=" form-group message-btn centred">
                              <button
                                class="theme-btn-one"
                                type="submit"
                                // name="submit-form"
                              >
                                Enroll Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <div class="col-lg-12 col-md-12 col-sm-12 column">
            <div class="related-job">
              <h2>Our Trained Students</h2>
              <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                <div class="job-sidebar" style={{display:"flex"}}>
                
                  <div class="sidebar-widget support-widget">
                    <div class="widget-content centred m-4">
                      <div class="pattern-layer">
                        <div
                          class="pattern-1"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-44.png)",
                          }}
                        ></div>
                        <div
                          class="pattern-2"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-45.png)",
                          }}
                        ></div>
                      </div>
                      <figure class="image-box">
                        <img
                          src="assets/images/resource/author-2.png"
                          alt=""
                        />
                      </figure>
                      <h3>Vinay</h3>
                      <span class="designation">React Developer</span>
                      <ul class="support-info">
                        <li>
                          Call: <a href="tel:8004561234">(800) 9456-1234</a>
                        </li>
                        <li>
                          <a href="mailto:baldwin@example.com">
                            vinaykumar@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="sidebar-widget support-widget">
                    <div class="widget-content centred m-4">
                      <div class="pattern-layer">
                        <div
                          class="pattern-1"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-44.png)",
                          }}
                        ></div>
                        <div
                          class="pattern-2"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-45.png)",
                          }}
                        ></div>
                      </div>
                      <figure class="image-box">
                        <img
                          src="assets/images/resource/author-2.png"
                          alt=""
                        />
                      </figure>
                      <h3>Rajesh</h3>
                      <span class="designation">React Developer</span>
                      <ul class="support-info">
                        <li>
                          Call: <a href="tel:8004561234">(800) 9456-1234</a>
                        </li>
                        <li>
                          <a href="mailto:baldwin@example.com">
                            rajeshkumar@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="sidebar-widget support-widget">
                    <div class="widget-content centred m-4">
                      <div class="pattern-layer">
                        <div
                          class="pattern-1"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-44.png)",
                          }}
                        ></div>
                        <div
                          class="pattern-2"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-45.png)",
                          }}
                        ></div>
                      </div>
                      <figure class="image-box">
                        <img
                          src="assets/images/resource/author-2.png"
                          alt=""
                        />
                      </figure>
                      <h3>Ravi Teja</h3>
                      <span class="designation">React Developer</span>
                      <ul class="support-info">
                        <li>
                          Call: <a href="tel:8004561234">(800) 9456-1234</a>
                        </li>
                        <li>
                          <a href="mailto:baldwin@example.com">
                            raviteja001@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="sidebar-widget support-widget">
                    <div class="widget-content centred m-4">
                      <div class="pattern-layer">
                        <div
                          class="pattern-1"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-44.png)",
                          }}
                        ></div>
                        <div
                          class="pattern-2"
                          style={{
                            backgroundImage:
                              "url(assets/images/shape/pattern-45.png)",
                          }}
                        ></div>
                      </div>
                      <figure class="image-box">
                        <img
                          src="assets/images/resource/author-2.png"
                          alt=""
                        />
                      </figure>
                      <h3>Adhitya</h3>
                      <span class="designation">React Developer</span>
                      <ul class="support-info">
                        <li>
                          Call: <a href="tel:8004561234">(800) 9456-1234</a>
                        </li>
                        <li>
                          <a href="mailto:baldwin@example.com">
                            adhitya021@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
      {/* <!-- React Course-details end --> */}
      <Footer/>
    </div>
  );
}

export default VLSIembeddedsystems;
