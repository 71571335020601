import React, { useState } from "react";
import tabImage1 from "./industries_11.png";
import tabImage2 from "./industries_12.png";
import tabImage3 from "./industries_13.jpg";
import tabImage4 from "./industries_14.jpg";
import tabImage5 from "./industries_15.jpg";

const Industries = () => {
  const [activeTab, setActiveTab] = useState("#tab-3");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <section className="industries-section bg-color-1">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/pattern-7.png)" }}
        ></div>
        <div className="auto-container">
          <div className="tabs-box">
            <div className="row clearfix">
              <div className="col-xl-6 col-lg-12 col-sm-12 btn-column">
                <div className="sec-title light">
                  <h2>Software Development Services</h2>
                </div>
                <div className="tab-btn-box">
                  <ul className="tab-btns tab-buttons clearfix">
                    <li
                      className={`tab-btn ${
                        activeTab === "#tab-3" ? "active-btn" : ""
                      }`}
                      onClick={() => handleTabClick("#tab-3")}
                      data-tab="#tab-3"
                    >
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-13.png" alt="" />
                      </figure>
                      <h3>WEB DESIGN & DEVELOPMENT</h3>
                    </li>
                    <li
                      className={`tab-btn ${
                        activeTab === "#tab-4" ? "active-btn" : ""
                      }`}
                      onClick={() => handleTabClick("#tab-4")}
                      data-tab="#tab-4"
                    >
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-11.png" alt="" />
                      </figure>
                      <h3>MOBILE APP DEVELOPMENT</h3>
                    </li>
                    <li
                      className={`tab-btn ${
                        activeTab === "#tab-5" ? "active-btn" : ""
                      }`}
                      onClick={() => handleTabClick("#tab-5")}
                      data-tab="#tab-5"
                    >
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-12.png" alt="" />
                      </figure>
                      <h3>DIGITAL MARKETING SERVICES</h3>
                    </li>
                    <li
                      className={`tab-btn ${
                        activeTab === "#tab-7" ? "active-btn" : ""
                      }`}
                      onClick={() => handleTabClick("#tab-7")}
                      data-tab="#tab-7"
                    >
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-15.png" alt="" />
                      </figure>
                      <h3>CORPORATE TRAINING</h3>
                    </li>
                    <li
                      className={`tab-btn ${
                        activeTab === "#tab-8" ? "active-btn" : ""
                      }`}
                      onClick={() => handleTabClick("#tab-8")}
                      data-tab="#tab-8"
                    >
                      <figure className="icon-box">
                        <img src="assets/images/icons/icon-14.png" alt="" />
                      </figure>
                      <h3>OFFLINE & ONLINE TRAINING</h3>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-sm-12 content-column">
                <div className="tabs-content">
                  <div
                    className={`tab ${
                      activeTab === "#tab-3" ? "active-tab" : ""
                    }`}
                    id="tab-3"
                  >
                    <div className="inner-box">
                      <figure className="image-box">
                        <img src={tabImage1} alt="" />
                      </figure>
                      <div className="content-box">
                        <div className="text">
                          <h2>Web Design & Development</h2>
                        </div>
                        <div className="line"></div>
                        <div className="link">
                          <a href="#">
                            <i className="flaticon-right-arrow-angle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "#tab-4" ? "active-tab" : ""
                    }`}
                    id="tab-4"
                  >
                    <div className="inner-box">
                      <figure className="image-box">
                        <img src={tabImage2} alt="" />
                      </figure>
                      <div className="content-box">
                        <div className="text">
                          <h2>Mobile App Development</h2>
                        </div>
                        <div className="line"></div>
                        <div className="link">
                          <a href="#">
                            <i className="flaticon-right-arrow-angle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "#tab-5" ? "active-tab" : ""
                    }`}
                    id="tab-5"
                  >
                    <div className="inner-box">
                      <figure className="image-box">
                        <img src={tabImage3} alt="" />
                      </figure>
                      <div className="content-box">
                        <div className="text">
                          <h2>Digital Marketing Services</h2>
                        </div>
                        <div className="line"></div>
                        <div className="link">
                          <a href="#">
                            <i className="flaticon-right-arrow-angle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "#tab-7" ? "active-tab" : ""
                    }`}
                    id="tab-7"
                  >
                    <div className="inner-box">
                      <figure className="image-box">
                        <img src={tabImage4} alt="" />
                      </figure>
                      <div className="content-box">
                        <div className="text">
                          <h2>Corporate Training</h2>
                        </div>
                        <div className="line"></div>
                        <div className="link">
                          <a href="#">
                            <i className="flaticon-right-arrow-angle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab ${
                      activeTab === "#tab-8" ? "active-tab" : ""
                    }`}
                    id="tab-8"
                  >
                    <div className="inner-box">
                      <figure className="image-box">
                        <img src={tabImage5} alt="" />
                      </figure>
                      <div className="content-box">
                        <div className="text">
                          <h2>Offline & Online Training</h2>
                        </div>
                        <div className="line"></div>
                        <div className="link">
                          <a href="#">
                            <i className="flaticon-right-arrow-angle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industries;
