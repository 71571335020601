import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";

const Clients = () => {
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 1500, // Set autoplay timeout to 5000 milliseconds (5 seconds)
    autoplayHoverPause: true,
    nav: false,
    dots: false,
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      600: { items: 3 },
      800: { items: 4 },
      1000: { items: 5 },
      1200: { items: 6 },
    },
  };
  const optionsRtl = {
    ...options,
    rtl: true, // Add rtl option to scroll from right to left
  };
  return (
    <div>
      <section
        class="awards-section"
        style={{ marginTop: "-100px", marginBottom: "-180px" }}
      >
        <div class="auto-container">
          <div class="sec-title centred">
            <h2>100+ leading companies hire through Ideants</h2>
            <p>Get an opportunity to work with industry leaders</p>
          </div>
          <div class="row clearfix"></div>
        </div>
      </section>
      <section className="clients-section">
        <div className="outer-container mt-5">
          <OwlCarousel options={options}>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/google.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/amazon.png" alt="spinny" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/tcs.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/infosys.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/hcl.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/techmahindra.png" alt="" />
            </figure>
          </OwlCarousel>
          <OwlCarousel options={optionsRtl}>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/wipro.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/lnt.png" alt="spinny" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/oracle.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/ltimindtree.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/mphasis.jpg" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/cognizant.png" alt="" />
            </figure>
          </OwlCarousel>
          <OwlCarousel options={options}>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/collebora.jpg" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/microsoft.png" alt="spinny" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/zoho.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/apple.jpg" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/accenture.png" alt="" />
            </figure>
            <figure className="clients-logo-box">
              <img src="assets/images/clients/adobe.png" alt="" />
            </figure>
          </OwlCarousel>
        </div>
      </section>
    </div>
  );
};

export default Clients;
