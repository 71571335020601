import React from "react";
import { Link } from "react-router-dom";

const VLSICerificationTraining = () => {
  return (
    <section className="container-fluid welcome-section">
      <div
        className="auto-container pt-5 pb-5 "
        style={{ backgroundColor: "wheat" }}
      >
        <div className="sec-title centred">
          <h2>VLSI Certification Training Program</h2>
          <p>
            PG Level Advanced Certification Programme in VLSI Chip Design
            <br />
            VLSI Course for Working Professionals{" "}
          </p>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <figure className="image-box js-tilt">
              <img src="assets/images/courses/vlsi-physical-design.jpg" alt="vlsi-certification" style={{borderRadius:"32px", border:"5px solid purple"}}/>
            </figure>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div id="content_block_1">
              <div className="content-box">
                <div className="tabs-box">
                  <div className="tabs-content">
                    <div className="tab active-tab" id="tab-1">
                      <div className="inner-box">
                        <h2>4 Reasons Why This Programme is Unique </h2>
                        <p>
                          Delivered by top researchers in VLSI Design and
                          Application
                        </p>
                        <p>
                          Hands on curriculum for effective learning by
                          practitioners
                        </p>
                        <p>Network with future leaders of chip design</p>
                        <p>
                          Learn on TalentSprint’s patent-pending Digital
                          Platform
                        </p>

                        <div className="row ml-1 pb-2">
                          <div className="viewmore p-4 m-1 ">
                            <a href="/VLSI-Certification-training-program">
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VLSICerificationTraining;
